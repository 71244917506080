import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
//Import slider
import SlitherSlider from 'slither-slider';
Vue.use(SlitherSlider)
import('./assets/css/custom.css')
//Import file routes
import routes from './routes'

Vue.use(VueRouter);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.config.productionTip = false

const router = new VueRouter({
  routes: routes
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')


