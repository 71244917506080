<template>
  <div class="pad-nav-bottom">
    <NavSakura></NavSakura>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="12" align-self="center">
            <h3 class="subtitle-h3-white text-left">
              Project
              <a v-bind:href="''" class="pointer-events-none">
                <img
                  src="../assets/img/icon-menu-alert.svg"
                  class="icon-menu-footer float-right"
                />
              </a>
              <a v-bind:href="''" class="pointer-events-none">
                <img
                  src="../assets/img/icon-search-white.svg"
                  class="icon-menu-footer float-right mr-3"
                />
              </a>
              <a v-bind:href="''" class="pointer-events-none">
                <img
                  src="../assets/img/icon-filter-alert.svg"
                  class="icon-menu-footer float-right mr-3"
                />
              </a>
            </h3>
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row class="mt-2 mb-1 border-bottom">
            <b-col cols="12">
              <div class="header-detail-project text-left mb-1">
                <p class="font-black-16-bold mt-3">Select project</p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col id="scroll-table" cols="12">
              <table class="responsive">
                <tr>
                  <th width="100px">PROJECT</th>
                  <th>DISCIPLINE</th>
                  <th>GENRE</th>
                  <th>YEAR</th>
                  <th>STATUS</th>
                  <th>ROI</th>
                </tr>
                <tr>
                  <td width="100px">
                    <a v-bind:href="'#/profilessakuramanager/project/looking-at-the-sky'" class="pointer-events-none"
                      >Random roads</a
                    >
                  </td>
                  <td>Music</td>
                  <td>Pop</td>
                  <td>2021</td>
                  <td>Idea</td>
                  <td>Singer</td>
                </tr>
                <tr>
                  <td>
                    <a v-bind:href="'#/profilessakuramanager/project/looking-at-the-sky'" class="text-s-purple"
                      >Looking at the Sky</a
                    >
                  </td>
                  <td>Music</td>
                  <td>Latin Techno</td>
                  <td>2021</td>
                  <td>Produce</td>
                  <td>Producer</td>
                </tr>
                <tr>
                  <td>
                    <a v-bind:href="'#/profilessakuramanager/project/looking-at-the-sky'" class="pointer-events-none"
                      >Light it up</a
                    >
                  </td>
                  <td>Music</td>
                  <td>Rock</td>
                  <td>2021</td>
                  <td>Idea</td>
                  <td>Composition</td>
                </tr>
                <tr>
                  <td>
                    <a v-bind:href="'#/profilessakuramanager/project/looking-at-the-sky'" class="pointer-events-none"
                      >Parallel microcosm</a
                    >
                  </td>
                  <td>Music</td>
                  <td>Pop</td>
                  <td>2021</td>
                  <td>Produce</td>
                  <td>Producer</td>
                </tr>
                <tr>
                  <td>
                    <a v-bind:href="'#/profilessakuramanager/project/looking-at-the-sky'" class="pointer-events-none"
                      >Sorry</a
                    >
                  </td>
                  <td>Music</td>
                  <td>Pop</td>
                  <td>2021</td>
                  <td>Produce</td>
                  <td>Producer</td>
                </tr>
                <tr>
                  <td>
                    <a v-bind:href="'#/profilessakuramanager/project/looking-at-the-sky'" class="pointer-events-none"
                      >Heavenly rooms</a
                    >
                  </td>
                  <td>Music</td>
                  <td>Latin</td>
                  <td>2021</td>
                  <td>Idea</td>
                  <td>Composition</td>
                </tr>
                <tr>
                  <td>
                    <a v-bind:href="'#/profilessakuramanager/project/looking-at-the-sky'" class="pointer-events-none"
                      >Love Yourself</a
                    >
                  </td>
                  <td>Music</td>
                  <td>Pop</td>
                  <td>2021</td>
                  <td>Produce</td>
                  <td>Singer</td>
                </tr>
                <tr>
                  <td>
                    <a v-bind:href="'#/profilessakuramanager/project/looking-at-the-sky'" class="pointer-events-none"
                      >The starry unicorn</a
                    >
                  </td>
                  <td>Music</td>
                  <td>Pop</td>
                  <td>2021</td>
                  <td>Idea</td>
                  <td>Composition</td>
                </tr>
                <tr>
                  <td>
                    <a v-bind:href="'#/profilessakuramanager/project/looking-at-the-sky'" class="pointer-events-none"
                      >Shape of you</a
                    >
                  </td>
                  <td>Music</td>
                  <td>Latin Techno</td>
                  <td>2021</td>
                  <td>Produce</td>
                  <td>Singer</td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-container>

      </div>
    </div>
    <NavSakuraFooter></NavSakuraFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
import NavSakura from "../components/includes/NavSakura.vue";
import NavSakuraFooter from "../components/includes/NavSakuraFooter.vue";
export default {
  data() {
    return {
      text: `Pending entrees in project. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor`,
      fields: ["PROJECT", "DISCIPLINE", "GENRE", "YEAR", "STATUS", "ROI"],
      state: {
        blogPosts: [
          {
            id: 1,
            date: "03/02/2019",
            title: "This is my first title",
            image: "This is my first image",
            status: "Live",
            edit: "Edit",
            delete: "Delete",
          },
          {
            id: 2,
            date: "03/02/2019",
            title: "This is my first title",
            image: "This is my first image",
            status: "Paused",
            edit: "Edit",
            delete: "Delete",
          },
          {
            id: 3,
            date: "03/02/2019",
            title: "This is my first title",
            image: "This is my first image",
            status: "Live",
            edit: "Edit",
            delete: "Delete",
          },
        ],
      },
    };
  },
  components: {
    NavSakura,
    NavSakuraFooter,
  },
};
</script>

