<template>
  <div class="pad-nav-bottom">
    <Nav></Nav>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="10" align-self="center">
            <h3 class="subtitle-detail-alert text-left">
              Pre Production:<br />
              <b>Looking at the Sky</b><br />
              <span class="mt-2 d-inline-block">Edit</span>
            </h3>
          </b-col>
          <b-col cols="2" align-self="center">
            <img
              id="show-btn"
              @click="$bvModal.show('actions-detail-alert')"
              src="../assets/img/icon-ein-menu-more.svg"
              class="icon-menu-footer float-right pulse-white"
            />
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row class="mt-2 mb-2">
            <b-col cols="12">
              <div class="header-detail-project text-left border-bottom">
                <p class="subtitle">Project Stage</p>
                <p class="title">
                  Pre Production
                  <img
                    src="../assets/img/icon-edit.svg"
                    class="icon-menu-footer float-right"
                  />
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col cols="12">
              <div class="content-project pt-4 text-left">
                <p class="font-size-gray-12 mb-3">Objectives you define</p>
                <div class="item-content-detail mt-3">
                  <p class="font-size-black-16-bold">Close team</p>
                  <b-progress :value="value1" class="w-100 mb-2"></b-progress>
                  <p class="text-right porcentage-text">82%</p>
                </div>
                <div class="item-content-detail mt-3">
                  <p class="font-size-black-16-bold">Create a demo</p>
                  <b-progress :value="value2" class="w-100 mb-2"></b-progress>
                  <p class="text-right porcentage-text">100%</p>
                </div>
                <div class="item-content-detail mt-3">
                  <p class="font-size-black-16-bold">Look for investment</p>
                  <b-progress :value="value3" class="w-100 mb-2"></b-progress>
                  <p class="text-right porcentage-text">20%</p>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-modal id="actions-detail-alert" hide-footer>
          <template #modal-title> </template>
          <div class="d-block text-left padding-general">
            <a v-bind:href="'#/home/alerts/detail'">
              <p class="font-black-18 mb-5">
                <img
                  src="../assets/img/icon-pop-up-review-project.svg"
                  class="icon-modal mr-2"
                />
                Review project
              </p>
            </a>
            <a v-bind:href="'#/home/alerts/detail'">
              <p class="font-black-18 mb-5">
                <img
                  src="../assets/img/icon-pop-up-edit-project.svg"
                  class="icon-modal mr-2"
                />
                Edit project
              </p>
            </a>
            <p v-b-modal.close-project class="font-black-18 mb-5 text-s-purple">
              <img
                src="../assets/img/icon-pop-up-close-project.svg"
                class="icon-modal mr-2"
              />
              Close project
            </p>
            <div class="text-center">
              <img
                src="../assets/img/icon-arrow-down-purple.svg"
                class="mx-auto"
              />
            </div>
          </div>
        </b-modal>
        <b-modal
          ref="close-project"
          id="close-project"
          centered
          hide-footer
          title="Notification"
        >
          <div class="d-block text-center">
            <p class="text">
              To close the project you need to make sure all the fields you<br />
              projected are filed:
            </p>
            <p class="text-small text-left mb-5">
              <img
                src="../assets/img/icon-close-project-hover.svg"
                class="icon-modal mr-2"
              />Lead guitarist is missing.
            </p>
          </div>
          <a href="#/collaborators/search">
          <b-button class="mt-2 btn-purple-type-2-custom pulse" block
            >Go to Project Details</b-button
          >
          </a>
          <b-button class="mt-3 btn-dark-type-1-custom" block @click="hideModal"
            >Cancelar</b-button
          >
        </b-modal>
      </div>
    </div>
    <NavFooter></NavFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
import Nav from "../components/includes/Nav.vue";
import NavFooter from "../components/includes/NavFooterNoPulse.vue";
export default {
  methods: {
    hideModal() {
      this.$refs["close-project"].hide();
    },
  },
  data() {
    return {
      value1: 75,
      value2: 100,
      value3: 20,
    };
  },
  components: {
    Nav,
    NavFooter,
  },
};
</script>

