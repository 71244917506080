<template>
  <div class="pt-2 bg-purple">
    <div class="padding-general">
      <b-container>
        <b-row class="mb-4">
          <b-col class="text-left">
            <img
              alt="Vue logo"
              src="../assets/img/logo-ein-sign-in-screen.svg"
            />
          </b-col>
          <b-col class="text-right" align-self="center"> </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col class="text-left text-white">
            <h1 class="title-light">Welcome back<br><b>to ein</b></h1>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="bg-white border-top-left-right padding-general">
      <b-container>
        <b-row class="mt-5 mb-4">
          <b-col>
            <div class="wrapper fadeInDown">
              <div id="formContent">
                <!-- Login Form -->
                <form id="form-login" class="form-style">
                  <div class="form-group">
                    <label>Email Address</label><br />
                    <input
                      type="text"
                      id="login"
                      class="fadeIn second"
                      name="login"
                      label="Email"
                      placeholder=""
                    />
                  </div>
                  <div class="form-group">
                    <label>Password</label><br />
                    <input
                      :type="password_type"
                      id="password"
                      class="fadeIn third"
                      name="login"
                      placeholder=""
                    />
                  </div>
                  <p class="h5 mb-2 text-right">
                    <b-icon @click="togglePassword()" icon="eye"></b-icon>
                  </p>
                  <b-row class="mt-5 mb-4">
                    <b-col>
                      <b-button block class="btn-dark-type-2-custom"
                        >Sign In</b-button
                      >
                    </b-col>
                  </b-row>
                </form>
                <!-- Remind Passowrd -->
                <b-row id="formFooter" class="w-100 text-left">
                  <b-col>
                    <a class="underlineHover pointer-events-none" href="#">Forgot Password?</a>
                  </b-col>
                </b-row>
                <!-- Create Account -->
                <b-row  class="text-center mt-5">
                  <b-col id="join-us">
                    <a class="underlineHover text-s-purple" href="#/joinus">Not a member? Join Us.</a>          
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
export default {
  data() {
    return {
      password: "",
      password_type: "password",
      password_toggle_element: "show",
    };
  },

  methods: {
    togglePassword() {
      this.password_type =
        this.password_type === "password" ? "text" : "password";
    },
  },
};
</script>

