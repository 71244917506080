<template>
  <div>
    <div id="nav-footer-fix">
      <b-row class="mt-5 mb-1 pl-2 pr-2">
        <b-col id="send-text-message" cols="12">
          <b-input-group-append>
            <b-form-input
              type="text"
              placeholder="Write a message"
            ></b-form-input>
            <b-input-group-text>
              <b-icon icon="arrow-right" />
            </b-input-group-text>
          </b-input-group-append>
        </b-col>
      </b-row>
      <b-row>
         <b-col cols="12">
          <div v-if="isHidden">
          <img
            src="../../assets/img/icon-arrow-up-purple.svg"
            class="icon-info-user"
             v-on:click="isHidden = !isHidden"
          />
          </div>
          <div v-if="!isHidden">
          <img
            src="../../assets/img/icon-arrow-down-purple.svg"
            class="icon-info-user"
            v-on:click="isHidden = !isHidden"
          />
          </div>
        </b-col>
      </b-row>
      <b-row
        v-if="!isHidden"
        id="menu-footer"
        class="mt-2 pt-4 border-top mb-2"
      >
        <b-col cols="12">
          <b-button-group>
            <b-button
              v-bind:href="'#/profileana/alerts'"
              variant="outline-primary" class="pointer-events-none"
            >
              <img
                src="../../assets/img/icon-ein-menu-home-OFF.svg"
                class="icon-menu-footer"
              />
              Home
            </b-button>
            <b-button
              v-bind:href="'#/profileinvestorsteiner/alerts2'"
              variant="outline-primary" class="pulse"
            >
              <img
                src="../../assets/img/icon-ein-menu-alerts-ON-notification.svg"
                class="icon-menu-footer"
              />
              Alerts
            </b-button>
            <b-button
              v-bind:href="'#/profileinvestorsteiner/alerts'"
              variant="outline-primary" class="pointer-events-none"
            >
              <img
                src="../../assets/img/icon-ein-menu-calendar-OFF.svg"
                class="icon-menu-footer"
              />
              Calendar
            </b-button>
            <b-button
              v-bind:href="'#/profileinvestorsteiner/alerts'"
              variant="outline-primary" class="pointer-events-none"
            >
              <img
                src="../../assets/img/icon-ein-menu-chat-OFF.svg"
                class="icon-menu-footer " 
              />
              Chat
            </b-button>
            <b-button
              v-bind:href="'#/profileinvestorsteiner/alerts'"
              variant="outline-primary" class="pointer-events-none"
            >
              <img
                src="../../assets/img/icon-ein-menu-task-OFF.svg"
                class="icon-menu-footer" 
              />
              Task
            </b-button>
            <b-button
              v-bind:href="'#/profileinvestorsteiner/alerts'"
              variant="outline-primary" class="pointer-events-none"
            >
              <img
                src="../../assets/img/icon-ein-menu-profile-OFF.svg"
                class="icon-menu-footer"
              />
              Profile
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isHidden: false,
    };
  },
};
</script>