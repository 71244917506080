<template>
  <div class="pt-5 bg-purple">
    <div class="padding-general">
      <b-container>
        <b-row class="mb-4">
          <b-col class="text-left">
            <img alt="Vue logo" src="../assets/img/logo-ein-white.svg" />
          </b-col>
          <b-col class="text-right" align-self="center"> </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col class="text-left text-white">
            <h1 class="title-light">¡Welcome to <b>ein</b>!</h1>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="bg-white border-top-left-right padding-general">
      <b-container>
        <b-row class="mt-5 mb-4">
          <b-col>
            <h3 class="title-register-profile text-left">
              Register on ein to see <b>Producer James</b>’s project.
            </h3>
            <div class="wrapper fadeInDown">
              <div id="formContent">
                <!-- Login Form -->
                <form id="form-login" class="form-style mt-5">
                  <div class="form-group">
                    <b-form-select
                      v-model="selected"
                      :options="options"
                      class="mb-3 custom-select border-radius-10"
                    >
                      <!-- This slot appears above the options from 'options' prop -->
                      <template #first>
                        <b-form-select-option :value="null" disabled 
                          >Select language…</b-form-select-option
                        >
                      </template>

                      <!-- These options will appear after the ones from 'options' prop -->
                      <b-form-select-option value="C"
                        >Spanish</b-form-select-option
                      >
                      <b-form-select-option value="D"
                        >English</b-form-select-option
                      >
                    </b-form-select>
                    <a href="#/profileana/register">
                      <b-button block class="btn-purple-type-1-custom mt-2 pulse"
                        >Register</b-button
                      >
                    </a>
                  </div>
                </form>
                <b-row class="text-center mt-5">
                  <b-col class="text-footer-form mt-5">
                    <p>
                      If you are already an ein user
                      <a class="underlineHover pointer-events-none" href="" >click here.</a>
                    </p>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
export default {
  data() {
    return {
      selected: null,
      options: [
        //{ value: "A", text: "Option A (from options prop)" },
        //{ value: "B", text: "Option B (from options prop)" },
      ],
    };
  },

  methods: {},
};
</script>

