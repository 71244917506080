<template>
  <div class="pad-nav-bottom">
    <NavSakura></NavSakura>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="10" align-self="center"> 
            <h3 class="subtitle-detail-alert text-left pad-head-back">
              <a
                v-bind:href="'#/profilessakuramanager/select-project'"
                class="float-left link-back link-absolute"
              >
                <img src="../assets/img/icon-arrow-left-white.svg" />
              </a>
              <b>Project: Looking at the Sky</b><br />
              <span class="d-inline-block">Gantt</span>
            </h3>
          </b-col>
          <b-col cols="2" align-self="center">
            <img
              id="show-btn"
              @click="$bvModal.show('recording-modal')"
              src="../assets/img/icon-header-more.svg"
              class="icon-menu-footer float-right"
            />
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row class="mt-2 mb-1">
            <b-col cols="12">
              <div
                class="header-detail-project text-left mb-3 d-inline-block w-100"
              >
                <p class="font-black-16-bold mt-3">
                  Planing
                  <a
                    v-bind:href="'#/profilessakuramanager/project/looking-at-the-sky'"
                    class="btn btn-small btn-a-gray float-right"
                    >Resources</a
                  ><a
                    v-bind:href="'#/profilessakuramanager/project/looking-at-the-sky'"
                    class="btn btn-small btn-a-purple float-right mr-2"
                    >Production</a
                  >
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col id="scroll-table" cols="12">
              <a v-bind:href="'#/profilessakuramanager/project/looking-at-the-sky2'">
              <img src="../assets/img/ein-gantt.jpg" class="gantt-1" />
              </a>
            </b-col>
          </b-row>
        </b-container>
        <b-modal
          ref="recording-modal"
          id="recording-modal"
          centered
          hide-footer
          title=""
        >
          <h3 class="title-modal-header-white border-bottom mb-4 pb-4">
            Recording<br />
            <span>Co-Production </span>
          </h3>
          <p>
            <span class="color-purple font-size-12"
              >25/02/2021 to 28/02/2021</span
            >
          </p>
          <p class="font-size-12">
            <b> 2 Sopranos: </b>
          </p>
          <b-row id="contacts-content" class="mt-3 mb-5">
            <b-col cols="12">
              <b-list-group>
                <b-list-group-item
                  class="d-flex align-items-center contacts-modal"
                >
                  <b-avatar class="mr-3">
                    <img
                      src="../assets/img/img-profile-miranda-lucia.png"
                      class="icon_user"
                    />
                  </b-avatar>
                  <span class="text-left"> Miranda Lucia Whelt </span>
                </b-list-group-item>
                <b-list-group-item
                  class="d-flex align-items-center contacts-modal"
                >
                  <b-avatar class="mr-3">
                    <img
                      src="../assets/img/img-profile-sakura-manager.png"
                      class="icon_user"
                    />
                  </b-avatar>
                  <span class="text-left"> Sakura Manager </span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <b-button block class="btn-purple-type-1-custom">Accept</b-button>
          <b-button
            @click="$bvModal.show('close-project')" 
            block
            class="btn-dark-type-1-custom mt-3 mb-2"
            >Modify</b-button
          >
        </b-modal>
        <b-modal
          ref="close-project"
          id="close-project"
          centered
          hide-footer
          title="Notification"
        >
          <b-list-group id="resumen-card" class="text-left mb-4">
            <b-list-group-item>
              <p><b>Recording</b> <span class="float-right">Co-Production </span></p>
            </b-list-group-item>
            <b-list-group-item>
              <p><b>Date</b> <span class="float-right">25/02/2021 to 28/02/2021</span></p>
            </b-list-group-item>
          </b-list-group>
          <p class="text-small mt-5 mb-5 text-left">
           Accepting changes will take you to another screen, your changes will be saved.
          </p>
        <a v-bind:href="'#/profilessakuramanager/project/projectupdate'">
            <b-button block class="btn-purple-type-1-custom">Accept</b-button>
          </a>
          <b-button block class="btn-dark-type-1-custom mt-3 mb-2"
            >Cancel</b-button
          >
        
        </b-modal>
      </div>
    </div>
    <NavSakuraFooter></NavSakuraFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
import NavSakura from "../components/includes/NavSakura.vue";
import NavSakuraFooter from "../components/includes/NavSakuraFooter.vue";
export default {
  data() {
    return {
      text: `Pending entrees in project. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor`,
    };
  },
  components: {
    NavSakura,
    NavSakuraFooter,
  },
};
</script>

