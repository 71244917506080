<template>
  <div class="pad-nav-bottom">
    <NavAna></NavAna>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="10" align-self="center">
            <h3 class="subtitle-detail-alert text-left">
              Pre Production:<br />
              <b>Looking at the Sky</b><br />
              <span class="mt-2 d-inline-block"
                >Chat / <b>Collaboration agreement</b></span
              >
            </h3>
          </b-col>
          <b-col cols="2" align-self="center">
            <img
              id="show-btn"
              @click="$bvModal.show('actions-detail-alert')"
              src="../assets/img/icon-header-more.svg"
              class="icon-menu-footer float-right pulse-white"
            />
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row class="mt-2 mb-1">
            <b-col cols="12">
              <div class="header-detail-project text-left border-bottom mb-1">
                <p class="font-black-12-bold mt-3">
                  Work proposal
                  <span class="text-price-project float-right"
                    >Guitarist
                    <span class="color-purple font-weight-bold"
                      >${{ number }}</span
                    >
                    / day
                  </span>
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-3 mb-2">
            <b-col id="content-chat" cols="12 ">
              <div class="bg-purple-light p-3 mt-4 text-left mb-4">
                <h3 class="text-purple-bold text-left font-size-14">
                  In this presentation we do not show the complete sequence of
                  the chat since it has already been shown when presenting
                  Producer James.
                </h3>
                <p class="color-purple font-size-14 mt-2">
                  If you want to access the complete chat, click on the image of
                  <b class="text-purple">Producer James</b>. From the chat you can return here by clicking
                  on the image of Ana Artist.
                </p>
                <p class="color-purple font-size-14 mt-2">
                  For a better understanding we show the first page (this one)
                  and the last one (the next one).
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col id="send-text-message" cols="12">
              <b-input-group-append>
                <b-form-input
                  type="text"
                  placeholder="Write a message"
                ></b-form-input>
                <b-input-group-text>
                  <b-icon icon="arrow-right" />
                </b-input-group-text>
              </b-input-group-append>
            </b-col>
          </b-row>
        </b-container>
        <numericpad
          v-bind:class="{ active: isActive }"
          :onInput="onInput"
          :onDelete="onDelete"
          :onReset="onReset"
          :show="showKeypad"
        />
        <b-modal id="actions-detail-alert" hide-footer>
          <template #modal-title> </template>
          <div class="d-block text-left padding-general">
            <a v-bind:href="'#/home/alerts/detail'">
              <p class="font-black-18 mb-5">
                <img
                  src="../assets/img/icon-ein-view-password-black.svg"
                  class="icon-modal mr-2"
                />
                Review project
              </p>
            </a>

            <p v-b-modal.close-project class="font-black-18 mb-5 text-s-purple">
              <img
                src="../assets/img/icon-close-project.svg"
                class="icon-modal mr-2"
              />
              Close project
            </p>
          </div>
        </b-modal>
        <b-modal
          ref="close-project"
          id="close-project"
          centered
          hide-footer
          title="Terms"
        >
          <div class="d-block text-left">
            <p class="font-size-12">
              Once you accept the terms of agreement you are subject to legal
              review by law and the ein platform statutes if the terms are
              violated. You will receive a copy of the chat and agreement in
              your inbox and email.
              <span
                class="
                  color-purple
                  text-underline text-uppercase
                  font-weight-bold
                "
                >Modify email</span
              >
            </p>
            <p class="text font-size-12">
              To insure and secure, all payments are to be made through the ein
              platform and into the card you have declared to be your main
              account.
              <span
                class="
                  color-purple
                  text-underline text-uppercase
                  font-weight-bold
                "
                >Modify card</span
              >
            </p>
            <p class="text font-size-12">
              Remember ein doesn’t retain any tax on your behalf and any
              payments required by law in your country are responsibility of the
              user.
              <span
                class="
                  color-purple
                  text-underline text-uppercase
                  font-weight-bold
                "
                >I have a different legal entity.</span
              >
            </p>
            <p class="text font-size-12">
              As soon as <b>Producer James</b> processes the upfront payment of
              30%, as declared in the terms ein will take 0.5% as a commission
              cost, tax included.
            </p>
          </div>
          <a v-bind:href="'#/profileana/alerts2'">
            <b-button class="mt-2 btn-purple-type-1-custom pulse" block
              >Sign</b-button
            >
          </a>
          <b-button class="mt-3 btn-dark-type-1-custom" block @click="hideModal"
            >Cancelar</b-button
          >
        </b-modal>
      </div>
    </div>
    <NavAnaFooter></NavAnaFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
.keypad-dialog {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.keypad-dialog {
  display: none;
}
.keypad-dialog.active {
  display: initial;
  z-index: 2000;
}
</style>
<script>
import numericpad from "@/components/numeric-keypad.vue";
import NavAna from "../components/includes/NavAna.vue";
import NavAnaFooter from "../components/includes/NavAnaFooter.vue";
export default {
  methods: {
    hideModal() {
      this.$refs["close-project"].hide();
    },
    onInput(key) {
      this.number = (this.number + key).slice(0, this.maxLength);
    },
    onDelete() {
      this.number = this.number.slice(0, this.number.length - 1);
    },
    onReset() {
      this.number = "";
    },
  },
  data() {
    return {
      isActive: false,
      selected: [], // Must be an array reference!
      options: [
        { text: "You have worked together on ein in the past.", value: "1" },
        {
          text: "I want ein to recommend a possible collaborator.",
          value: "2",
        },
        {
          text: "I know someone that would like to be on ein that I can contact directly.",
          value: "3",
        },
      ],
      value1: 75,
      value2: 100,
      value3: 20,
      number: "150",
      maxLength: 4,
      showKeypad: true,
    };
  },
  components: {
    NavAna,
    NavAnaFooter,
    numericpad,
  },
};
</script>

