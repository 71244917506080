<template>
  <div class="pad-nav-bottom">
    <Nav></Nav>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="12" align-self="center">
            <h3 class="subtitle-h3-white text-left">
              Transaction history
              
                <img
                  src="../assets/img/icon-filter-alert.svg"
                  class="icon-menu-footer float-right mr-3"
                />
              
            </h3>
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row id="list-transaction" class="mt-2 mb-2">
            <b-col cols="12">
              <slither-slider
                :options="{
                  adaptiveHeight: true,
                  autoplay: false,
                  controls: false,
                }"
              >
                <!-- Slide 1 -->
                <div>
                  <b-list-group>
                    <b-list-group-item>
                      <p class="w-100 d-inline-block text-left">
                        <a
                          v-bind:href="'#/negotiation/detail-transaction'"
                          class="title-transaction text-s-purple"
                        >
                          Commission 0,25%
                        </a>
                        <span
                          class="date-text float-right price-transaction text-red"
                        >
                          -$3.75
                        </span>
                      </p>
                      <p class="w-100 d-inline-block text-right">
                        <span class="float-left date-transaction"
                          >20/09/2020, Ein</span
                        >
                        <span class="date-text float-right status-transaction"
                          >Paid</span
                        >
                      </p>
                    </b-list-group-item>
                    <b-list-group-item>
                      <p class="w-100 d-inline-block text-left">
                        <a
                          v-bind:href="'#/negotiation/detail-transaction'"
                          class="title-transaction pointer-events-none"
                        >
                          Second production cost “Silence at Night”
                        </a>
                        <span
                          class="date-text float-right price-transaction text-black"
                        >
                          $6,500
                        </span>
                      </p>
                      <p class="w-100 d-inline-block text-right">
                        <span class="float-left date-transaction"
                          >17/03/2020, Werner Sounds</span
                        >
                        <span class="date-text float-right status-transaction"
                          >Pending</span
                        >
                      </p>
                    </b-list-group-item>
                    <b-list-group-item>
                      <p class="w-100 d-inline-block text-left">
                        <a
                          v-bind:href="'#/negotiation/detail-transaction'"
                          class="title-transaction pointer-events-none"
                        >
                          Transaction commission 0,25%
                        </a>
                        <span
                          class="date-text float-right price-transaction text-red"
                        >
                          -$31,25
                        </span>
                      </p>
                      <p class="w-100 d-inline-block text-right">
                        <span class="float-left date-transaction"
                          >16/03/2020, Ein</span
                        >
                        <span class="date-text float-right status-transaction"
                          >Paid</span
                        >
                      </p>
                    </b-list-group-item>
                    <b-list-group-item>
                      <p class="w-100 d-inline-block text-left">
                        <a
                          v-bind:href="'#/negotiation/detail-transaction'"
                          class="title-transaction pointer-events-none"
                        >
                          Production “Amantes os Lovers”
                        </a>
                        <span
                          class="date-text float-right price-transaction text-green"
                        >
                          $12.500
                        </span>
                      </p>
                      <p class="w-100 d-inline-block text-right">
                        <span class="float-left date-transaction"
                          >12/02/2020, Sakura Events</span
                        >
                        <span class="date-text float-right status-transaction"
                          >Received
                        </span>
                      </p>
                    </b-list-group-item>
                    <b-list-group-item>
                      <p class="w-100 d-inline-block text-left">
                        <a
                          v-bind:href="'#/negotiation/detail-transaction'"
                          class="title-transaction pointer-events-none"
                        >
                          Transaction commission 0,25%
                        </a>
                        <span
                          class="date-text float-right price-transaction text-red"
                        >
                          -$16,25
                        </span>
                      </p>
                      <p class="w-100 d-inline-block text-right">
                        <span class="float-left date-transaction"
                          >10/02/2020, Ein</span
                        >
                        <span class="date-text float-right status-transaction"
                          >Paid
                        </span>
                      </p>
                    </b-list-group-item>
                    <b-list-group-item>
                      <p class="w-100 d-inline-block text-left">
                        <a
                          v-bind:href="'#/negotiation/detail-transaction'"
                          class="title-transaction pointer-events-none"
                        >
                          Transaction commission 0,25%
                        </a>
                        <span
                          class="date-text float-right price-transaction text-green"
                        >
                          $6.500
                        </span>
                      </p>
                      <p class="w-100 d-inline-block text-right">
                        <span class="float-left date-transaction"
                          >01/02/2020, Werner Sounds</span
                        >
                        <span class="date-text float-right status-transaction"
                          >Received
                        </span>
                      </p>
                    </b-list-group-item>
                  </b-list-group>
                </div>

                <!-- Slide 2 -->
                <div>
                  <b-list-group>
                    <b-list-group-item>
                      <p class="w-100 d-inline-block text-left">
                        <a
                          v-bind:href="'#/negotiation/detail-transaction'"
                          class="title-transaction"
                        >
                          Commission 0,25%
                        </a>
                        <span
                          class="date-text float-right price-transaction text-red"
                        >
                          -$3.75
                        </span>
                      </p>
                      <p class="w-100 d-inline-block text-right">
                        <span class="float-left date-transaction"
                          >20/09/2020, Ein</span
                        >
                        <span class="date-text float-right status-transaction"
                          >Paid</span
                        >
                      </p>
                    </b-list-group-item>
                    <b-list-group-item>
                      <p class="w-100 d-inline-block text-left">
                        <a
                          v-bind:href="'#/negotiation/detail-transaction'"
                          class="title-transaction pointer-events-none"
                        >
                          Second production cost “Silence at Night”
                        </a>
                        <span
                          class="date-text float-right price-transaction text-black"
                        >
                          $6,500
                        </span>
                      </p>
                      <p class="w-100 d-inline-block text-right">
                        <span class="float-left date-transaction"
                          >17/03/2020, Werner Sounds</span
                        >
                        <span class="date-text float-right status-transaction"
                          >Pending</span
                        >
                      </p>
                    </b-list-group-item>
                    <b-list-group-item>
                      <p class="w-100 d-inline-block text-left">
                        <a
                          v-bind:href="'#/negotiation/detail-transaction'"
                          class="title-transaction pointer-events-none"
                        >
                          Transaction commission 0,25%
                        </a>
                        <span
                          class="date-text float-right price-transaction text-red"
                        >
                          -$31,25
                        </span>
                      </p>
                      <p class="w-100 d-inline-block text-right">
                        <span class="float-left date-transaction"
                          >16/03/2020, Ein</span
                        >
                        <span class="date-text float-right status-transaction"
                          >Paid</span
                        >
                      </p>
                    </b-list-group-item>
                    <b-list-group-item>
                      <p class="w-100 d-inline-block text-left">
                        <a
                          v-bind:href="'#/negotiation/detail-transaction'"
                          class="title-transaction pointer-events-none"
                        >
                          Production “Amantes os Lovers”
                        </a>
                        <span
                          class="date-text float-right price-transaction text-green"
                        >
                          $12.500
                        </span>
                      </p>
                      <p class="w-100 d-inline-block text-right">
                        <span class="float-left date-transaction"
                          >12/02/2020, Sakura Events</span
                        >
                        <span class="date-text float-right status-transaction"
                          >Received
                        </span>
                      </p>
                    </b-list-group-item>
                    <b-list-group-item>
                      <p class="w-100 d-inline-block text-left">
                        <a
                          v-bind:href="'#/negotiation/detail-transaction'"
                          class="title-transaction pointer-events-none"
                        >
                          Transaction commission 0,25%
                        </a>
                        <span
                          class="date-text float-right price-transaction text-red"
                        >
                          -$16,25
                        </span>
                      </p>
                      <p class="w-100 d-inline-block text-right">
                        <span class="float-left date-transaction"
                          >10/02/2020, Ein</span
                        >
                        <span class="date-text float-right status-transaction"
                          >Paid
                        </span>
                      </p>
                    </b-list-group-item>
                    <b-list-group-item>
                      <p class="w-100 d-inline-block text-left">
                        <a
                          v-bind:href="'#/negotiation/detail-transaction'"
                          class="title-transaction pointer-events-none"
                        >
                          Transaction commission 0,25%
                        </a>
                        <span
                          class="date-text float-right price-transaction text-green"
                        >
                          $6.500
                        </span>
                      </p>
                      <p class="w-100 d-inline-block text-right">
                        <span class="float-left date-transaction"
                          >01/02/2020, Werner Sounds</span
                        >
                        <span class="date-text float-right status-transaction"
                          >Received
                        </span>
                      </p>
                    </b-list-group-item>
                  </b-list-group>
                </div>

                <!-- Slide 3 -->
                <div>
                  <b-list-group>
                    <b-list-group-item>
                      <p class="w-100 d-inline-block text-left">
                        <a
                          v-bind:href="'#/negotiation/detail-transaction'"
                          class="title-transaction"
                        >
                          Commission 0,25%
                        </a>
                        <span
                          class="date-text float-right price-transaction text-red"
                        >
                          -$3.75
                        </span>
                      </p>
                      <p class="w-100 d-inline-block text-right">
                        <span class="float-left date-transaction"
                          >20/09/2020, Ein</span
                        >
                        <span class="date-text float-right status-transaction"
                          >Paid</span
                        >
                      </p>
                    </b-list-group-item>
                    <b-list-group-item>
                      <p class="w-100 d-inline-block text-left">
                        <a
                          v-bind:href="'#/negotiation/detail-transaction'"
                          class="title-transaction pointer-events-none"
                        >
                          Second production cost “Silence at Night”
                        </a>
                        <span
                          class="date-text float-right price-transaction text-black"
                        >
                          $6,500
                        </span>
                      </p>
                      <p class="w-100 d-inline-block text-right">
                        <span class="float-left date-transaction"
                          >17/03/2020, Werner Sounds</span
                        >
                        <span class="date-text float-right status-transaction"
                          >Pending</span
                        >
                      </p>
                    </b-list-group-item>
                    <b-list-group-item>
                      <p class="w-100 d-inline-block text-left">
                        <a
                          v-bind:href="'#/negotiation/detail-transaction'"
                          class="title-transaction pointer-events-none"
                        >
                          Transaction commission 0,25%
                        </a>
                        <span
                          class="date-text float-right price-transaction text-red"
                        >
                          -$31,25
                        </span>
                      </p>
                      <p class="w-100 d-inline-block text-right">
                        <span class="float-left date-transaction"
                          >16/03/2020, Ein</span
                        >
                        <span class="date-text float-right status-transaction"
                          >Paid</span
                        >
                      </p>
                    </b-list-group-item>
                    <b-list-group-item>
                      <p class="w-100 d-inline-block text-left">
                        <a
                          v-bind:href="'#/negotiation/detail-transaction'"
                          class="title-transaction pointer-events-none"
                        >
                          Production “Amantes os Lovers”
                        </a>
                        <span
                          class="date-text float-right price-transaction text-green"
                        >
                          $12.500
                        </span>
                      </p>
                      <p class="w-100 d-inline-block text-right">
                        <span class="float-left date-transaction"
                          >12/02/2020, Sakura Events</span
                        >
                        <span class="date-text float-right status-transaction"
                          >Received
                        </span>
                      </p>
                    </b-list-group-item>
                    <b-list-group-item>
                      <p class="w-100 d-inline-block text-left">
                        <a
                          v-bind:href="'#/negotiation/detail-transaction'"
                          class="title-transaction pointer-events-none"
                        >
                          Transaction commission 0,25%
                        </a>
                        <span
                          class="date-text float-right price-transaction text-red"
                        >
                          -$16,25
                        </span>
                      </p>
                      <p class="w-100 d-inline-block text-right">
                        <span class="float-left date-transaction"
                          >10/02/2020, Ein</span
                        >
                        <span class="date-text float-right status-transaction"
                          >Paid
                        </span>
                      </p>
                    </b-list-group-item>
                    <b-list-group-item>
                      <p class="w-100 d-inline-block text-left">
                        <a
                          v-bind:href="'#/negotiation/detail-transaction'"
                          class="title-transaction pointer-events-none"
                        >
                          Transaction commission 0,25%
                        </a>
                        <span
                          class="date-text float-right price-transaction text-green"
                        >
                          $6.500
                        </span>
                      </p>
                      <p class="w-100 d-inline-block text-right">
                        <span class="float-left date-transaction"
                          >01/02/2020, Werner Sounds</span
                        >
                        <span class="date-text float-right status-transaction"
                          >Received
                        </span>
                      </p>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </slither-slider>
            </b-col>
          </b-row>        
        </b-container>
      </div>
    </div>
    <NavFooter></NavFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
.pad-nav-bottom {
    padding-bottom: 130px;
}
</style>
<style>
@import "../../node_modules/slither-slider/plugin-dist/slither-slider.css";
</style>
<script>
import Nav from "../components/includes/Nav.vue";
import NavFooter from "../components/includes/NavFooterNoPulse.vue";
//import NavFooter from "../components/includes/MenuFooter.vue";
export default {
  data() {
    return {
      text: `
          Pending entrees in project. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
        `,
    };
  },
  components: {
    Nav,
    NavFooter
  },
};
</script>

