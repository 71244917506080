<template>
  <div class="pad-nav-bottom">
    <NavAna></NavAna>
    <div class="pt-5 bg-purple mt-m-1">
      <div class="bg-white border-top-left-right padding-general">
        <b-container class="mt-5 mb-1">
          <b-row class="mb-3">
            <b-col>
              <a
                v-bind:href="'#/profileana/register'"
                class="float-left link-back"
              >
                <img src="../assets/img/icon-arrow-left-black.svg" />
              </a>
              <h2 class="title-header">
                Register<br />
                <span class="text-light">Personal info</span>
              </h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="wrapper fadeInDown">
                <p class="font-black-12 mt-2 text-left">
                  To get the most out of ein we have created ix, it will help
                  you create more work opportunities, connections and overall
                  help you achieve your objectives.
                </p>
                <b-form-group
                  label=""
                  v-slot="{ ariaDescribedby }"
                  class="text-left checks-policy"
                  id=""
                >
                  <b-form-checkbox
                    size="lg"
                    v-for="option in options"
                    v-model="selected"
                    :key="option.value"
                    :value="option.value"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-4a"
                    inline
                  >
                    {{ option.text }}
                  </b-form-checkbox>
                </b-form-group>
                <p class="font-black-12 mt-4 text-left">
                  Allowing the before mentioned accesses will help you
                  participate on ein in a coordinated way thus achieving a
                  better and healthier network for all. ein Will never sell your
                  personal data, info, locations and any other data received to
                  absolutely any third party without your consent, if data is
                  requested by a third party we believe that you should make
                  money off of selling it, so, we will let you know.
                </p>
                <p class="text-left">
                  <a href="" class="color-purple text-bold-capital">
                    Privacy statement and notices
                  </a>
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-4 mb-4">
            <b-col>
              <a v-bind:href="'#/profileana/register-ix-activation'">
                <b-button block class="btn-purple-type-1-custom pulse"
                  >Authorize</b-button
                >
              </a>
              <a v-bind:href="'#/profileana/register-policy'">
                <b-button block class="btn-dark-type-1-custom mt-3"
                  >Cancel</b-button
                >
              </a>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
      <NavAnaFooter></NavAnaFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
import NavAna from "../components/includes/NavAna.vue";
import NavAnaFooter from "../components/includes/NavAnaFooter.vue";
export default {
  data() {
    return {
      value: 30,
      options: [
        {
          text: "Grow your contacts with contacts of your contacts.",
          value: "1",
        },
        {
          text:
            "Allow ein to manage your space-time availability with access to your calendar.",
          value: "2",
        },
        {
          text:
            "Allow access to your location and enhance the coordination experience.",
          value: "3",
        },
      ],
    };
  },

  methods: {
    chooseFiles: function () {
      document.getElementById("fileUpload").click();
    },
  },
  components: {
    NavAna,
    NavAnaFooter
  },
};
</script>

