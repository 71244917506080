<template>
  <div class="pad-nav-bottom">
    <NavInvestor></NavInvestor>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="12" align-self="center">
            <a
              v-bind:href="'#/profileinvestorsteiner/register'"
              class="float-left link-back"
            >
              <img src="../assets/img/icon-arrow-left-white.svg" class="mr-3" />
            </a>
            <h3 class="title-heder-user text-left mt-1">
              <b>Right Space:</b> Looking at the Sky
            </h3>
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container class="mt-4 mb-1">
          <b-row class="mt-2 mb-2">
            <b-col cols="12">
              <div class="header-detail-project text-left">
                <p class="title">Investment Opportunity</p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col cols="12">
              <p class="text-left font-size-12">
                <b>Investor Steiner</b><br />
                <a href="mailto:asteiner@somemail.com<" class="color-purple"
                  >asteiner@somemail.com</a
                ><br />
                Would like to invest in your production
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="wrapper fadeInDown pb-4 mt-2">
                <p class="font-size-12 text-left text-gray mt-3 mb-2">
                  Investment deal*
                </p>
                <select name="select" class="select select-oportunity mt-0">
                  <option value="">Choose</option>
                  <option value="500000">$500,000</option>
                  <option value="250000">$250,000</option>
                  <option value="125000">$125,000</option>
                  <option value="Personalized" selected>Personalized</option>
                </select>
                <p class="font-size-12 text-left text-gray mt-3 mb-2">
                  Message
                </p>
                <b-form-textarea
                  class="text-box"
                  id="textarea"
                  v-model="text"
                  placeholder="James, Sakura has told me all about you. 
I would like to connect to discuss a possible 
partnership ."
                  rows="5"
                  max-rows="5"
                ></b-form-textarea>
                <b-form-checkbox
                  class="check-oportunity mt-4"
                  size="lg"
                  v-for="option in options"
                  v-model="selected"
                  :key="option.value"
                  :value="option.value"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-4a"
                  inline
                >
                  {{ option.text }}
                </b-form-checkbox>
                <b-button
                  @click="$bvModal.show('save-change')"
                  block
                  class="btn-purple-type-1-custom mt-4 pulse"
                  >Send</b-button
                >
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-modal
          ref="save-change"
          id="save-change"
          centered
          hide-footer
          title=""
        >
          <img
            src="../assets/img/icon-close.svg"
            @click="$bvModal.hide('save-change')"
            class="icon-close-modal-v2"
          />
          <b-row class="mt-0 mb-2 text-center">
            <b-col cols="12">
              <img src="../assets/img/icon-tick-notification.svg" />
            </b-col>
          </b-row>
          <b-row class="mt-3 mb-5">
            <b-col cols="12" class="text-center">
              <h3 class="text-white mb-4">
                Your investment petition has been sent successfully
              </h3>
              <p class="text-white font-size-12">
                Follow the investment <span class="font-weight-bold">Here</span>
              </p>
              <a v-bind:href="'#/profile/select-user'">
                <b-button block class="btn-dark-type-2-custom mt-5 box-s-white"
                  >Continue</b-button
                >
              </a>
            </b-col>
          </b-row>
        </b-modal>
      </div>
    </div>
    <NavInvestorFooter></NavInvestorFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<style>
@import "../../node_modules/slither-slider/plugin-dist/slither-slider.css";
</style>
<script>
import NavInvestor from "../components/includes/NavInvestor.vue";
import NavInvestorFooter from "../components/includes/NavInvestorFooter.vue";
export default {
  data() {
    return {
      value: 30,
      options: [
        {
          text: "Ask Producer James to chat.",
          value: "1",
        },
      ],
    };
  },

  methods: {
    chooseFiles: function () {
      document.getElementById("fileUpload").click();
    },
    hideModal() {
      this.$refs["save-change"].hide();
    },
  },
  components: {
    NavInvestor,
    NavInvestorFooter,
  },
};
</script>

