<template>
  <div class="pt-5 bg-purple">
    <div class="bg-white border-top-left-right padding-general">
      <b-container class="mt-5 mb-1">
        <b-row class="mb-3">
          <b-col>
            <a
              v-bind:href="'#/profileana/register'"
              class="float-left link-back"
            >
              <img src="../assets/img/icon-arrow-left-black.svg" />
            </a>
            <h2 class="title-header">
              Register<br />
              <span class="text-light">Allow Access</span>
            </h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="wrapper fadeInDown">
              <div id="formContent" class="value-text-bold">
                <!-- Login Form -->
                <form id="form-register" class="form-style">
                  <div class="form-group">
                    <input id="fileUpload" type="file" hidden />
                    <img @click="chooseFiles()" class="avatar-upload-user" src="../assets/img/img-profile-ana-artist-camera.png" />

                    <label>Name</label><br />
                    <input
                      type="text"
                      id="user"
                      class="fadeIn second"
                      name="user"
                      label="user"
                      placeholder=""
                      value="Ana"
                    />
                  </div>
                  <div class="form-group">
                    <label>Password</label><br />
                    <input
                      :type="text"
                      id="textpassword"
                      class="fadeIn third"
                      name="textpassword"
                      placeholder=""
                      value="Artist"
                    />
                  </div>
                  <div class="form-group">
                    <label>Artistic Name or Nickname (optional)</label><br />
                    <input
                      :type="text"
                      id="nicname"
                      class="fadeIn third"
                      name="nicname"
                      placeholder=""
                      value=""
                    />
                  </div>
                  <b-row class="mt-4 mb-4">
                    <b-col class="text-left">
                      <p class="text-id-small">ein ID (designed algorithm)</p>
                      <p class="color-purple font-weight-bold">AA0198239468</p>
                    </b-col>
                  </b-row>
                  <b-row class="mt-4 mb-4">
                    <b-col>
                      <a v-bind:href="'#/profileana/register-policy'">
                        <b-button block class="btn-purple-type-1-custom pulse"
                          >Register</b-button
                        >
                      </a>
                      <a v-bind:href="'#/profileana/register'">
                        <b-button block class="btn-dark-type-1-custom mt-3"
                          >Cancel</b-button
                        >
                      </a>
                    </b-col>
                  </b-row>
                </form>
                <!-- Create Account -->
                <b-row class="text-center mt-3">
                  <b-col id="join-us">
                    <ul class="list-inline text-center">
                      <li class="list-inline-item">
                        <div class="circle-step"></div>
                      </li>
                      <li class="list-inline-item">
                        <div class="circle-step active"></div>
                      </li>
                    </ul>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
export default {
  data() {
    return {
      value: 30,
    };
  },

  methods: {
    chooseFiles: function () {
      document.getElementById("fileUpload").click();
    },
  },
};
</script>

