<template>
  <div class="pad-nav-bottom">
    <NavSakura></NavSakura>
    <div class="pt-2 bg-black">
      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row class="mt-2 mb-3 pl-10 pr-10" align-self="center">
            <b-col id="search-home" cols="12">
              <b-form-input
                type="search"
                class="search-input"
                placeholder="Search..."
              >
              </b-form-input>
              <img
                src="../assets/img/icon-search.svg"
                class="icon-s-absolute"
              />
            </b-col>
          </b-row>

          <b-row id="content-cats" align-v="center">
            <div cols="12">
              <div id="scroll-home">
                <img src="../assets/img/menu-burbujas-sakura-manager-outline.png" class="bubbles-sakura"/>
              </div>
            </div>
            <!--<div class="circle">
              <span>rights</span>
            </div>
            <div class="circle">
              <span>contacts</span>
            </div>
            <div class="circle">
              <span>sale/rent</span>
            </div>
            <div class="circle">
              <span>opportunities</span>
            </div>
            <div class="circle">
              <span><b-icon icon="align-center"></b-icon></span>
            </div>
            <div class="circle">
              <span>shortcuts</span>
            </div>
            <div class="circle">
              <span>work in progress</span>
            </div>
            <div class="circle">
              <span>events</span>
            </div>-->
          </b-row>
        </b-container>
      </div>
    </div>
    <NavSakuraFooter></NavSakuraFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
import NavSakura from "../components/includes/NavSakura.vue";
import NavSakuraFooter from "../components/includes/NavSakuraFooterPulse.vue";
export default {
  components: {
    NavSakura,
    NavSakuraFooter,
  },
};
</script>

