<template>
  <div class="pad-nav-bottom">
    <NavSakura></NavSakura>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="10" align-self="center">
            <a v-bind:href="'#/profilessakuramanager/chat'" class="float-left link-back">
              <img src="../assets/img/icon-arrow-left-white.svg" class="mt-2 mr-2"/>
            </a>
            <h3 class="title-heder-user text-left"><img src="../assets/img/image-profile-chat-investor-steiner-2.png" width="40" /> Investor Steiner</h3>
          </b-col>
          <b-col cols="2" align-self="center">
            <img
              id="show-btn"
              src="../assets/img/icon-header-more.svg"
              class="icon-menu-footer float-right"
            />
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general pb-4">
        <b-container>
          <b-row class="mt-3 mb-2">
            <b-col id="content-chat" cols="12 ">
              <div class="recivid-chat mb-2">
                <div class="text-chat">
                  <div class="bg-chat">
                    <p>
                      Hi Sakura, I want to ask you something about Producer
                      James.
                    </p>
                  </div>
                  <p class="date-chat">11/08/2020, 19:21</p>
                </div>
              </div>
              <div class="send-chat">
                <div class="img-chat"></div>
                <div class="text-chat">
                  <div class="bg-chat mb-2">
                    <p>Hi Steiner! How goes it, what do you want to know?</p>
                  </div>
                  <p class="date-chat">
                    11/08/2020, 19:21
                    <img src="../assets/img/icon-tick-square-white.svg" />
                  </p>
                </div>
              </div>
              <div class="recivid-chat mb-2">
                <div class="text-chat">
                  <div class="bg-chat">
                    <p>
                      Ein sent me his project and I want a good reference on
                      him.
                    </p>
                  </div>
                  <p class="date-chat">11/08/2020, 19:21</p>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <NavSakuraFooter></NavSakuraFooter>
  </div>
</template>

<script>
import NavSakura from "../components/includes/NavSakura.vue";
import NavSakuraFooter from "../components/includes/NavSakuraFooterChat.vue";
export default {
  methods: {

  },
  data() {
    return {
      isActive: false,
      selected: [], // Must be an array reference!
      options: [
        { text: "You have worked together on ein in the past.", value: "1" },
        {
          text: "I want ein to recommend a possible collaborator.",
          value: "2",
        },
        {
          text:
            "I know someone that would like to be on ein that I can contact directly.",
          value: "3",
        },
      ],
      value1: 75,
      value2: 100,
      value3: 20,
      number: "150",
      maxLength: 4,
      showKeypad: true,
    };
  },
  components: {
    NavSakura,
    NavSakuraFooter,
  },
};
</script>

