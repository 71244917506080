<template>
  <div class="pad-nav-bottom">
    <Nav></Nav>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="10" align-self="center">
            <h3 class="subtitle-detail-alert text-left">
              Pre Production:<br />
              <b>Looking at the Sky</b><br />
              <span class="mt-2 d-inline-block"
                >Edit / <b>Invite partner</b></span
              >
            </h3>
          </b-col>
          <b-col cols="2" align-self="center">
            <img
              id="show-btn"
              @click="$bvModal.show('actions-detail-alert')"
              src="../assets/img/icon-ein-menu-more.svg"
              class="icon-menu-footer float-right"
            />
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row class="mt-3 mb-3">
            <b-col cols="12">
              <p class="font-black-12 mt-4">
                This is how your contact will see it:
              </p>
            </b-col>
          </b-row>

          <b-row class="mt-2 mb-4">
            <b-col cols="12">
              <div class="content-input-send-email border-bottom mb-1">
                <div class="text-send-email"><p>To:</p></div>
                <div class="input-send-email">
                  <img src="../assets/img/icon-JC-02-mail-AA.svg" class="float-left"/>
                  <b-form-input
                  class="float-left"
                    v-model="email"
                    placeholder="Ana Artist"
                  ></b-form-input>
                </div>
              </div>
              <div class="content-input-send-email border-bottom mb-1">
                <div class="text-send-email"><p>Cc / Bcc:</p></div>
                <div class="input-send-email">
                  <b-form-input v-model="text" placeholder=""></b-form-input>
                </div>
              </div>
              <div class="content-input-send-email border-bottom mb-1">
                <div class="text-send-email"><p>From:</p></div>
                <div class="input-send-email">
                  <b-form-input
                    v-model="email"
                    placeholder="producerjames@somemail.com"
                  ></b-form-input>
                </div>
              </div>
              <div class="content-input-send-email border-bottom mb-1">
                <div class="text-send-email"><p>Re:</p></div>
                <div class="input-send-email">
                  <b-form-input
                    v-model="text"
                    placeholder="Do you want to collaborate in a project…"
                  ></b-form-input>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-1 pt-1 pb-1 mb-1">
            <b-col id="text-content-email" cols="12">
              <div class="bg-purple-light p-3">
                <p>
                  Hi Ana, it's been a while. I'm working on a new project and
                  I'm looking for the best so I need you on guitar of course! We
                  are doing recordings and a world live tour.
                </p>
                <p>
                  Come check out all the details on my ein project and we can
                  discuss your pay.
                </p>
                <p>
                  I have set a 7 day timer in case I need to find someone else
                  in time.
                </p>
                <p>Best,</p>
                <p>
                  <span class="font-weight-bold">Producer James</span><br />
                  <span class="color-purple text-underline font-weight-bold"
                    >producerjames@somemail.com</span
                  ><br /><span class="text-underline">564.434.4234</span>
                </p>
              </div>
              <div class="bg-white p-3">
                <p>Hello Ana,</p>
                <p>
                  From ein we congratulate you because
                  <span class="font-weight-bold">Producer James</span> wants to
                  collaborate with you in a project.
                </p>
                <p>
                  To do so, you must register in ein, the project coordination
                  platform that will open the doors to new work and business
                  opportunities.
                </p>
                <p>
                  Once you have registered in ein you will have access to the
                  project information and you will be able to negotiate the
                  conditions through the ein chat.
                </p>
                <p>
                  You can download ein from
                  <span class="color-purple text-underline font-weight-bold"
                    >Apple's App Store</span
                  >
                  (IOS) or
                  <span class="color-purple text-underline font-weight-bold"
                    >Google's Play Store</span
                  >
                  (Android).
                </p>
                <p>
                  On the ein website you will find more information about the
                  platform including all the information about the privacy
                  policy, use and protection of personal data.
                </p>
                <p>
                  In any case you should know that your data will only be used
                  for the proper functioning of the coordination mechanisms of
                  ein.
                </p>
                <p>We are waiting for you!</p>
                <p class="text-uppercase color-purple font-weight-bold">
                  Download the application, register and negotiate the
                  agreement.
                </p>
                <p>Yours sincerely</p>
                <p class="font-weight-bold">The ein Team</p>
              </div>
              <div class="text-left p-2 mb-3">
                <img
                  src="../assets/img/cta-download-google-play.svg"
                  class="mr-2"
                />
                <img src="../assets/img/cta-download-app-store.svg" class="" />
              </div>
              <div class="bg-purple-light p-3">
                <p class="color-purple font-weight-bold text-14">
                  You have a maximum of 7 days to reply to this invitation but
                  you can register on ein at any time to discover the platforms
                  potential for you.
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-3 pt-1 pb-1 mb-1">
            <b-col cols="12">
              <b-button
                v-b-modal.close-project
                class="mt-2 btn-purple-type-2-custom pulse"
                block
              >
                Send email
              </b-button>
            </b-col>
          </b-row>
        </b-container>
        <b-modal
          ref="close-project"
          id="close-project"
          centered
          hide-footer
          title="Notification"
        >
          <div class="d-block text-center">
            <p class="text text-center mb-5">
              <b>Your email has been sent.</b><br><br>
              If there is no answer with in 7 days and
              the guest Ana Artist hasn’t been registered on ein we will send
              you both a new notice.
            </p>
          </div>
          <a v-bind:href="'#/home/alerts2'">
          <b-button class="mt-2 btn-purple-type-2-custom pulse" block
            >Continue</b-button
          >
          </a>
        </b-modal>
      </div>
    </div>
    <NavFooter></NavFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
.pad-nav-bottom {
    padding-bottom: 130px;
}
</style>
<script>
import Nav from "../components/includes/Nav.vue";
import NavFooter from "../components/includes/NavFooterNoPulse.vue";
export default {
  methods: {
    hideModal() {
      this.$refs["close-project"].hide();
    },
  },
  data() {
    return {
      selected: [], // Must be an array reference!
      options: [
        { text: "You have worked together on ein in the past.", value: "1" },
        {
          text: "I want ein to recommend a possible collaborator.",
          value: "2",
        },
        {
          text:
            "I know someone that would like to be on ein that I can contact directly.",
          value: "3",
        },
      ],
      value1: 75,
      value2: 100,
      value3: 20,
    };
  },
  components: {
    Nav,
    NavFooter
  },
};
</script>

