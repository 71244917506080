<template>
  <div class="pt-2 bg-purple">
    <b-container>
      <b-row class="mt-2 mb-3 pl-10 pr-10">
        <b-col class="text-left text-white" align-self="center">
          <a v-bind:href="'#/profile/select-user'" class="float-left link-back">
            <img src="../assets/img/icon-arrow-left-white.svg" />
          </a>
          <h3 class="subtitle-h3-white">Choose other character</h3>
        </b-col>
      </b-row>
    </b-container>

    <div class="bg-white border-top-left-right padding-general">
      <b-container>
        <b-row class="mt-5 mb-4">
          <b-col>
            <div class="wrapper fadeInDown">
              <b-row class="mt-2 mb-2">
                <b-col class="text-center name-profile">
                  <img
                    src="../assets/img/img-profile-ana-artist.png"
                    class="img-user-80"
                  />
                  <h3 class="font-semi-bold">Ana Artist</h3>
                  <p>ein user</p>
                </b-col>
              </b-row>
              <b-row class="mt-2 mb-2">
                <b-col id="sections" class="text-left w-100 scroll-y">
                  <div class="scroll-w">
                    <button class="btn-section-prfofile active mb-2">
                      Personal info
                    </button>
                    <button
                      @click="scrollMeTo('skills')"
                      class="btn-section-prfofile mb-2"
                    >
                      Disciplines and skills
                    </button>
                    <button
                      @click="scrollMeTo('ein')"
                      class="btn-section-prfofile mb-2"
                    >
                      Info
                    </button>
                    <button
                      @click="scrollMeTo('skills')"
                      class="btn-section-prfofile mb-2"
                    >
                      Disciplines and skills
                    </button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-4 mb-4">
                <b-col>
                  <ul
                    id="list-perfil"
                    class="list-unstyled text-left list-info-user"
                  >
                    <li>
                      <img
                        src="../assets/img/icon-ein-profile-user.svg"
                        class="icon-info-user"
                      />
                      23 years old<br />
                      8 years as a professional musician<br />
                      Owns a 1960 Gibson Les Paul guitar<br />
                    </li>
                    <li>
                      <img
                        src="../assets/img/icon-ein-profile-location.svg"
                        class="icon-info-user"
                      />
                      1900 Anthony Ave, The Bronx, NY 10457, EEUU
                    </li>
                    <li>
                      <img
                        src="../assets/img/icon-ein-profile-mail.svg"
                        class="icon-info-user"
                      />
                      <a
                        v-bind:href="':mailto:anaartist@somemail.com'"
                        class="link-purple-small"
                        >anaartist@somemail.com</a
                      >
                    </li>
                    <li>
                      <img
                        src="../assets/img/icon-ein-profile-company.svg"
                        class="icon-info-user"
                      />
                      <b>Company N/A</b>
                    </li>
                    <li>
                      <img
                        src="../assets/img/icon-ein-profile-hourly-wage.svg"
                        class="icon-info-user"
                      />
                      Hourly wage $200
                    </li>
                  </ul>
                </b-col>
              </b-row>
              <b-row ref="skills" class="mt-4 mb-4 border-top pt-4">
                <b-col class="text-left">
                  <div class="item-likes item-likes-green mr-2 mb-2">
                    <p>Music</p>
                  </div>
                  <div class="item-likes item-likes-green like-badge mr-2 mb-2">
                    <p>
                      Guitarist<b-badge class="badge-green">15 yrs</b-badge>
                    </p>
                  </div>
                  <div class="item-likes item-likes-green mr-2 mb-2">
                    <p>Acoustic</p>
                  </div>
                  <div class="item-likes item-likes-green mr-2 mb-2">
                    <p>Electric</p>
                  </div>
                  <div class="item-likes item-likes-green  mr-2 mb-2">
                    <p>
                      Rock N Roll
                    </p>
                  </div>
                  <div class="item-likes item-likes-yellow mr-2 mb-2">
                    <p>Funk</p>
                  </div>
                  <div class="item-likes item-likes-yellow mr-2 mb-2">
                    <p>Folk</p>
                  </div>
                  <div class="item-likes item-likes-yellow mr-2 mb-2">
                    <p>Writer</p>
                  </div>
                  <div class="item-likes item-likes-orange mr-2 mb-2">
                    <p>Script</p>
                  </div>
                  <div class="item-likes item-likes-orange mr-2 mb-2">
                    <p>Novelist</p>
                  </div>
                </b-col>
              </b-row>
              <b-row ref="ein" class="mt-4 mb-4 border-top pt-4">
                <b-col class="text-left">
                  <ul class="list-unstyled text-left list-info-user">
                    <li>
                      <img
                        src="../assets/img/icon-check.svg"
                        class="icon-info-user"
                      />
                     Invited by Producer James
                    </li>
                    <li>
                      <img
                        src="../assets/img/icon-check.svg"
                        class="icon-info-user"
                      />
                      New ix
                    </li>
                    <li>
                      <img
                        src="../assets/img/icon-check.svg"
                        class="icon-info-user"
                      />
                      Discovers she also has a professional link with Sakura
                    </li>
                  </ul>
                </b-col>
              </b-row>
              <b-row class="mt-4 mb-2">
                <b-col>
                  <a v-bind:href="'#/profileana/invitation'">
                    <b-button block class="btn-purple-type-2-custom pulse" 
                      >Enter</b-button
                    >
                  </a>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
export default {
  data() {
    return {
      password: "",
      password_type: "password",
      password_toggle_element: "show",
    };
  },

  methods: {
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
  },
};
</script>

