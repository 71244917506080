<template>
  <div class="pad-nav-bottom">
    <NavAna></NavAna>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="10" align-self="center">
            <h3 class="subtitle-detail-alert text-left">
              <b>Project:</b> Looking at the Sky<br />
              <span class="font-size-12">Negotiation with Joaquín Cortés</span>
            </h3>
          </b-col>
          <b-col cols="2" align-self="center">
            <img
              id="show-btn"
              @click="$bvModal.show('actions-detail-alert')"
              src="../assets/img/icon-header-more.svg"
              class="icon-menu-footer float-right"
            />
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row class="mt-2 mb-2">
            <b-col cols="12">
              <div class="header-detail-project text-left">
                <p class="title">Lorem ipsum</p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col cols="12">
              <b-list-group id="menu-sidebar" class="text-left">
                <b-list-group-item
                  ><img
                    src="../assets/img/icon-view-project.svg"
                    class="icon-nav mr-3" />
                  <a href="#/profileana/home" class="font-size-14 pointer-events-none"
                    >View Project and conditions</a
                  ><img
                    src="../assets/img/icon-arrow-right-black.svg"
                    class="arrow-right-register"
                /></b-list-group-item>
                <b-list-group-item
                  ><img
                    src="../assets/img/icon-acces-negeotiation.svg"
                    class="icon-nav mr-3" /><a href="#/profileana/negotiation-chat" class="font-size-14 text-s-purple"
                    >Access negotiation chat with Producer James</a
                  ><img
                    src="../assets/img/icon-arrow-right-black.svg"
                    class="arrow-right-register"
                /></b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-container>
        <b-modal id="actions-detail-alert" hide-footer>
          <template #modal-title> </template>
          <div class="d-block text-left padding-general">
            <a v-bind:href="'#/home/alerts/detail'">
              <p class="font-black-18 mb-5">
                <img
                  src="../assets/img/icon-ein-view-password-grey.svg"
                  class="icon-modal mr-2"
                />
                Review project
              </p>
            </a>
            <a v-bind:href="'#/home/alerts/detail'">
              <p class="font-black-18 mb-5">
                <img
                  src="../assets/img/icon-edit.svg"
                  class="icon-modal mr-2"
                />
                Edit project
              </p>
            </a>
            <p v-b-modal.close-project class="font-black-18 mb-5">
              <img
                src="../assets/img/icon-close-project.svg"
                class="icon-modal mr-2"
              />
              Close project
            </p>
          </div>
        </b-modal>
        <b-modal
          ref="close-project"
          id="close-project"
          centered
          hide-footer
          title="Notification"
        >
          <div class="d-block text-center">
            <p class="text">
              To close the project you need to make sure all the fields you<br />
              projected are filed:
            </p>
            <p class="text-small text-left mb-5">
              <img
                src="../assets/img/icon-close-project-hover.svg"
                class="icon-modal mr-2"
              />Lead guitarist is missing.
            </p>
          </div>
          <b-button class="mt-3 btn-dark-type-1-custom" block @click="hideModal"
            >Cancelar</b-button
          >
          <b-button class="mt-2 btn-purple-type-2-custom" block
            >Go to Project Details</b-button
          >
        </b-modal>
      </div>
    </div>
    <NavAnaFooter></NavAnaFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
import NavAna from "../components/includes/NavAna.vue";
import NavAnaFooter from "../components/includes/NavAnaFooter.vue";
export default {
  methods: {
    hideModal() {
      this.$refs["close-project"].hide();
    },
  },
  data() {
    return {
      value1: 75,
      value2: 100,
      value3: 20,
    };
  },
  components: {
    NavAna,
    NavAnaFooter,
  },
};
</script>

