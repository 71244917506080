<template>
  <div class="pad-nav-bottom">
    <NavSakura></NavSakura>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="10" align-self="center">
            <h3 class="subtitle-detail-alert text-left pad-head-back">
              <a
                v-bind:href="'#/profile/select-user'"
                class="float-left link-back link-absolute"
              >
                <img src="../assets/img/icon-arrow-left-white.svg" />
              </a>
              <b
                >Pre Production:<br />
                Looking at the Sky</b
              ><br />
              <span class="d-inline-block">Edit</span>
            </h3>
          </b-col>
          <b-col cols="2" align-self="center">
            <img
              id="show-btn"
              src="../assets/img/icon-header-more.svg"
              class="icon-menu-footer float-right"
            />
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row class="mt-2 mb-1">
            <b-col cols="12">
              <div
                class="
                  header-detail-project
                  text-left
                  mb-2
                  d-inline-block
                  w-100
                "
              >
                <p class="font-black-16-bold mt-3 mb-0">
                  <span class="font-weight-bold">Work Flow: </span>
                  <span class="font-weight-300">Professionals</span>
                </p>
                <p class="color-purple font-size-12 mt-1">Sopranos</p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-0 mb-3">
            <b-col id="list-professionals" cols="12">
              <b-list-group>
                <b-list-group-item class="text-left contacts-modal w-100">
                  <b-avatar class="mr-3">
                    <img
                      src="../assets/img/img-profile-miranda-lucia.png"
                      class="icon_user"
                    />
                  </b-avatar>
                  <span class="text-left"> Miranda Lucia Whelt </span>
                  <img
                    src="../assets/img/icon-change.svg"
                    class="shadow-purple float-right icon-change"
                  />
                </b-list-group-item>
                <b-list-group-item
                  class="text-left contacts-modal w-100"
                  v-if="isHidden"
                >
                  <b-avatar class="mr-3">
                    <img
                      src="../assets/img/img-profile-sakura-manager.png"
                      class="icon_user"
                    />
                  </b-avatar>
                  <span class="text-left"> Sakura Manager </span>
                  <img
                    src="../assets/img/icon-change.svg"
                    class="shadow-purple float-right icon-change pulse"
                    v-on:click="isHidden = false"
                  />
                </b-list-group-item>
                <b-list-group-item
                  class="text-left contacts-modal w-100"
                  v-if="!isHidden"
                >
                  <b-avatar class="mr-3">
                    <img
                      src="../assets/img/img-profile-razzida-sustakiev.png"
                      class="icon_user"
                    />
                  </b-avatar>
                  <span class="text-left"> Razzida Sustakiev </span>
                  <img
                    src="../assets/img/icon-change.svg"
                    class="shadow-purple float-right icon-change"
                    v-on:click="isHidden = true"
                  />
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mt-5 pb-3">
              <b-button
                v-if="!isHidden"
                @click="$bvModal.show('save-change')"
                block
                class="btn-purple-type-1-custom pulse"
              >
                Save changes
              </b-button>
              <b-button
                v-if="isHidden"
                @click="$bvModal.show('save-change')"
                block
                class="btn-purple-type-1-custom pointer-events-none"
              >
                Save changes
              </b-button>
              <b-button block class="btn-dark-type-1-custom mt-3 mb-2"
                >Cancel</b-button
              >
            </b-col>
          </b-row>
        </b-container>
        <b-modal
          ref="save-change"
          id="save-change"
          centered
          hide-footer
          title=""
        >
          <img src="../assets/img/icon-close.svg" @click="$bvModal.hide('save-change')" class="icon-close-modal-v2" />
          <b-row class="mt-0 mb-2 text-center">
            <b-col cols="12">
              <img src="../assets/img/icon-tick-notification.svg" />
            </b-col>
          </b-row>
          <b-row class="mt-3 mb-5">
            <b-col cols="12" class="text-center">
              <h3 class="text-white mb-4">
                Your changes have<br />been saved.
              </h3>
              <p class="text-white font-size-12">
                <span class="font-weight-bold">Ana Artist</span> has received
                the changes.
              </p>
              <a v-bind:href="'#/profilessakuramanager/chat'">
                <b-button block class="btn-dark-type-2-custom box-s-white"
                  >Continue</b-button
                >
              </a>
            </b-col>
          </b-row>
        </b-modal>
        <b-modal
          ref="close-project"
          id="close-project"
          centered
          hide-footer
          title="Notification"
        >
          <b-list-group id="resumen-card" class="text-left mb-4">
            <b-list-group-item>
              <p>
                <b>Recording</b> <span class="float-right">Co-Production </span>
              </p>
            </b-list-group-item>
            <b-list-group-item>
              <p>
                <b>Date</b>
                <span class="float-right">25/02/2021 to 28/02/2021</span>
              </p>
            </b-list-group-item>
          </b-list-group>
          <p class="text-small mt-5 mb-5 text-left">
            Accepting changes will take you to another screen, your changes will
            be saved.
          </p>
          <a v-bind:href="'#/profileana/list-alert'">
            <b-button block class="btn-purple-type-1-custom">Accept</b-button>
          </a>
          <b-button block class="btn-dark-type-1-custom mt-3 mb-2"
            >Cancel</b-button
          >
        </b-modal>
      </div>
    </div>
    <NavSakuraFooter></NavSakuraFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}

</style>
<script>
import NavSakura from "../components/includes/NavSakura.vue";
import NavSakuraFooter from "../components/includes/NavSakuraFooter.vue";
export default {
  data() {
    return {
      isHidden: true,
      text: `Pending entrees in project. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor`,
    };
  },
  methods: {
    hideModal() {
      this.$refs["save-change"].hide();
    },
  },
  components: {
    NavSakura,
    NavSakuraFooter,
  },
};
</script>

