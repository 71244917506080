//Import Components
import Login from './login/Login.vue';
import Signin from './login/Signin.vue';
import Create from './register/Create.vue';
import Phone from './register/Phone.vue';
import Code from './register/Code.vue';
import Home from './home/Home.vue';
/*Profile*/
import SelectProfile from './profile/SelectProfile.vue';
import DetailProfile from './profile/DetailProfile.vue';
/*Home*/
import Alerts from './home/Alerts.vue';
import Alerts2 from './home/Alerts2.vue';
import DetailAlerts from './home/DetailAlerts.vue';

/*Project*/
import SearchCollaborator from './collaborators/SearchCollaborator.vue';
import YourPhoneContacts from './collaborators/YourPhoneContacts.vue';
import InviteCollaborator from './collaborators/InviteCollaborator.vue';
import EmailCollaborator from './collaborators/EmailCollaborator.vue';
import NegotiationMessages from './negotiation/Messages.vue';
import NegotiationMessages2 from './negotiation/Messages-2.vue';
import NegotiationMessages3 from './negotiation/Messages-3.vue';
import NegotiationMessages4 from './negotiation/Messages-4.vue';
import NegotiationMessages5 from './negotiation/Messages-5.vue';
import NegotiationMessages6 from './negotiation/Messages-6.vue';
import NegotiationMessages7 from './negotiation/Messages-7.vue';
import NegotiationMessages8 from './negotiation/Messages-8.vue';
import NegotiationMessages9 from './negotiation/Messages-9.vue';
import NegotiationMessages10 from './negotiation/Messages-10.vue';
import NegotiationConfirmation from './negotiation/Confirmation.vue';
import NegotiationTransaction from './negotiation/Transaction.vue';
import NegotiationDetailTransaction from './negotiation/DetailTransaction.vue';
/*Profile Ana Detail*/
import ProfileAnaDetail from './profilesanaartist/DetailProfile.vue';
import ProfileAnaInvitation from './profilesanaartist/Invitation.vue';
import ProfileAnaWelcome from './profilesanaartist/Welcome.vue';
import ProfileAnaRegister from './profilesanaartist/Register.vue';
import ProfileAnaRegisterImage from './profilesanaartist/RegisterImage.vue';
import ProfileAnaRegisterPolicy from './profilesanaartist/RegisterAllowAccess.vue';
import ProfileAnaRegisterActivation from './profilesanaartist/RegisterActivation.vue';
import ProfileAnaRegisterEinSetup from './profilesanaartist/RegisterActivationSetup.vue';
import ProfileAnaRegisterPayment from './profilesanaartist/RegisterPayment.vue';
import ProfileAnaRegisterSuccess from './profilesanaartist/RegisterSuccess.vue';
import ProfileAnaHome from './profilesanaartist/Home.vue';
import ProfileAnaAlerts from './profilesanaartist/Alerts.vue';
import ProfileAnaAlerts2 from './profilesanaartist/Alerts2.vue';
import ProfileAnaAlerts3 from './profilesanaartist/Alerts3.vue';
import ProfileAnaListAlert from './profilesanaartist/ListAlert.vue';
import ProfileAnaNegotiationChat from './profilesanaartist/NegotationChat.vue';
import ProfileAnaNegotiationAgreement from './profilesanaartist/NegotiationAgreement.vue';
import ProfileAnaContacts from './profilesanaartist/Contacts.vue';
import ProfilesSakuraManager from './profilessakuramanager/DetailProfile.vue';
import ProfilesSakuraManagerCreate from './profilessakuramanager/Create.vue';
import ProfilesSakuraManagerPhone from './profilessakuramanager/Phone.vue';
import ProfilesSakuraManagerCode from './profilessakuramanager/Code.vue';
import ProfilesSakuraManagerHome from './profilessakuramanager/Home.vue';
import ProfilesSakuraManagerAlerts from './profilessakuramanager/Alerts.vue';
import ProfilesSakuraManagerSelectProject from './profilessakuramanager/SelectProject.vue';
import ProfilesSakuraManagerProjecLooking from './profilessakuramanager/Lookingsky.vue';
import ProfilesSakuraManagerProjecLooking2 from './profilessakuramanager/Lookingsky2.vue';
import ProfilesSakuraManagerProjecUpdate from './profilessakuramanager/ProjectUpdate.vue';
import ProfilesSakuraManagerChat from './profilessakuramanager/Chat.vue';
import ProfilesSakuraManagerChatMessage from './profilessakuramanager/ChatMessage.vue';
import ProfilesSakuraManagerChatMessage2 from './profilessakuramanager/ChatMessage2.vue';
import ProfileInvestorContacts from './profileinvestorsteiner/Contacts.vue';
import ProfileInvestorDetailUser from './profileinvestorsteiner/DetailProfile.vue';
import ProfileInvestorSignin from './profileinvestorsteiner/Signin.vue';
import ProfileInvestorCode from './profileinvestorsteiner/Code.vue';
import ProfileInvestorHome from './profileinvestorsteiner/Home.vue';
import ProfileInvestorAlerts from './profileinvestorsteiner/Alerts.vue';
import ProfileInvestorAlerts2 from './profileinvestorsteiner/Alerts2.vue';
import ProfileInvestorListContacts from './profileinvestorsteiner/ListContacts.vue';
import ProfileInvestorChat from './profileinvestorsteiner/Chat.vue';
import ProfileInvestorRegister from './profileinvestorsteiner/Register.vue';
import ProfileInvestorRegister2 from './profileinvestorsteiner/Register2.vue';
import ProfileInvestorLooking from './profileinvestorsteiner/ViewLooking.vue';
import ProfileInvestorJames from './profileinvestorsteiner/JamesProfile.vue';
import ProfileInvestorReservation from './profileinvestorsteiner/Reservation.vue';
import ProfileInvestorSelectUser from './profileinvestorsteiner/SelectUser.vue';

export default [
    {
        path: '/',
        component: Login,
        name: 'Login'
    },
    {
        path: '/signin',
        component: Signin,
        name: 'Signin'
    },
    {
        path: '/joinus',
        component: Create,
        name: 'Create'
    },
    {
        path: '/joinus/phone',
        component: Phone,
        name: 'Phone'
    },
    {
        path: '/joinus/Code',
        component: Code,
        name: 'Code'
    },
    {
        path: '/profile/select-user',
        component: SelectProfile,
        name: 'Select Profile'
    },
    {
        path: '/profile/detail-user',
        component: DetailProfile,
        name: 'Detail Profile'
    },
    {
        path: '/home',
        component: Home,
        name: 'Home'
    },
    {
        path: '/home/alerts',
        component: Alerts,
        name: 'Alerts'
    },
    {
        path: '/home/alerts2',
        component: Alerts2,
        name: 'Alerts2'
    },
    {
        path: '/home/alerts/detail',
        component: DetailAlerts,
        name: 'DetailAlerts'
    },
    {
        path: '/collaborators/search',
        component: SearchCollaborator,
        name: 'SearchCollaborator'
    },
    {
        path: '/collaborators/YourPhoneContacts',
        component: YourPhoneContacts,
        name: 'YourPhoneContacts'
    },
    {
        path: '/collaborators/InviteCollaborator',
        component: InviteCollaborator,
        name: 'InviteCollaborator'
    },
    {
        path: '/collaborators/EmailCollaborator',
        component: EmailCollaborator,
        name: 'EmailCollaborator'
    },
    {
        path: '/negotiation/messages',
        component: NegotiationMessages,
        name: 'NegotiationMessages'
    },
    {
        path: '/negotiation/messages2',
        component: NegotiationMessages2,
        name: 'NegotiationMessages2'
    },
    {
        path: '/negotiation/messages3',
        component: NegotiationMessages3,
        name: 'NegotiationMessages3'
    },
    {
        path: '/negotiation/messages4',
        component: NegotiationMessages4,
        name: 'NegotiationMessages4'
    },
    {
        path: '/negotiation/messages5',
        component: NegotiationMessages5,
        name: 'NegotiationMessages5'
    },
    {
        path: '/negotiation/messages6',
        component: NegotiationMessages6,
        name: 'NegotiationMessages6'
    },
    {
        path: '/negotiation/messages7',
        component: NegotiationMessages7,
        name: 'NegotiationMessages7'
    },
    {
        path: '/negotiation/messages8',
        component: NegotiationMessages8,
        name: 'NegotiationMessages8'
    },
    {
        path: '/negotiation/messages9',
        component: NegotiationMessages9,
        name: 'NegotiationMessages9'
    },
    {
        path: '/negotiation/messages10',
        component: NegotiationMessages10,
        name: 'NegotiationMessages10'
    },
    {
        path: '/negotiation/confirmation',
        component: NegotiationConfirmation,
        name: 'NegotiationConfirmation'
    },
    {
        path: '/negotiation/transaction',
        component: NegotiationTransaction,
        name: 'NegotiationTransaction'
    },
    {
        path: '/negotiation/detail-transaction',
        component: NegotiationDetailTransaction,
        name: 'NegotiationDetailTransaction'
    },
    {
        path: '/profileana/detail-profile',
        component: ProfileAnaDetail,
        name: 'ProfileAnaDetail'
    },
    {
        path: '/profileana/invitation',
        component: ProfileAnaInvitation,
        name: 'ProfileAnaInvitation'
    },
    {
        path: '/profileana/welcome',
        component: ProfileAnaWelcome,
        name: 'ProfileAnaWelcome'
    },
    {
        path: '/profileana/register',
        component: ProfileAnaRegister,
        name: 'ProfileAnaRegister'
    },
    {
        path: '/profileana/register-image',
        component: ProfileAnaRegisterImage,
        name: 'ProfileAnaRegisterImage'
    },
    {
        path: '/profileana/register-policy',
        component: ProfileAnaRegisterPolicy,
        name: 'ProfileAnaRegisterPolicy'
    },
    {
        path: '/profileana/register-ix-activation',
        component: ProfileAnaRegisterActivation,
        name: 'ProfileAnaRegisterActivation'
    },
    {
        path: '/profileana/register-ein-setup',
        component: ProfileAnaRegisterEinSetup,
        name: 'ProfileAnaRegisterEinSetup'
    },
    {
        path: '/profileana/register-payment',
        component: ProfileAnaRegisterPayment,
        name: 'ProfileAnaRegisterPayment'
    },
    {
        path: '/profileana/register-success',
        component: ProfileAnaRegisterSuccess,
        name: 'ProfileAnaRegisterSuccess'
    },
    {
        path: '/profileana/home',
        component: ProfileAnaHome,
        name: 'ProfileAnaHome'
    },
    {
        path: '/profileana/alerts',
        component: ProfileAnaAlerts,
        name: 'ProfileAnaAlerts'
    },
    {
        path: '/profileana/alerts2',
        component: ProfileAnaAlerts2,
        name: 'ProfileAnaAlerts2'
    },
    {
        path: '/profileana/alerts3',
        component: ProfileAnaAlerts3,
        name: 'ProfileAnaAlerts3'
    },
    {
        path: '/profileana/list-alert',
        component: ProfileAnaListAlert,
        name: 'ProfileAnaListAlert'
    },
    {
        path: '/profileana/negotiation-chat',
        component: ProfileAnaNegotiationChat,
        name: 'ProfileAnaNegotiationChat'
    },
    {
        path: '/profileana/negotiation-agreement',
        component: ProfileAnaNegotiationAgreement,
        name: 'ProfileAnaNegotiationAgreement'
    },
    {
        path: '/profileana/contacts',
        component: ProfileAnaContacts,
        name: 'ProfileAnaContacts'
    },
    {
        path: '/profilessakuramanager/detail-user',
        component: ProfilesSakuraManager,
        name: 'ProfilesSakuraManager'
    },
    {
        path: '/profilessakuramanager/create',
        component: ProfilesSakuraManagerCreate,
        name: 'ProfilesSakuraManagerCreate'
    },
    {
        path: '/profilessakuramanager/phone',
        component: ProfilesSakuraManagerPhone,
        name: 'ProfilesSakuraManagerPhone'
    },
    {
        path: '/profilessakuramanager/code',
        component: ProfilesSakuraManagerCode,
        name: 'ProfilesSakuraManagerCode'
    },
    {
        path: '/profilessakuramanager/home',
        component: ProfilesSakuraManagerHome,
        name: 'ProfilesSakuraManagerHome'
    },
    {
        path: '/profilessakuramanager/alerts',
        component: ProfilesSakuraManagerAlerts,
        name: 'ProfilesSakuraManagerAlerts'
    },
    {
        path: '/profilessakuramanager/select-project',
        component: ProfilesSakuraManagerSelectProject,
        name: 'ProfilesSakuraManagerSelectProject'
    },
    {
        path: '/profilessakuramanager/project/looking-at-the-sky',
        component: ProfilesSakuraManagerProjecLooking,
        name: 'ProfilesSakuraManagerProjecLooking'
    },
    {
        path: '/profilessakuramanager/project/looking-at-the-sky2',
        component: ProfilesSakuraManagerProjecLooking2,
        name: 'ProfilesSakuraManagerProjecLooking2'
    },
    {
        path: '/profilessakuramanager/project/projectupdate',
        component: ProfilesSakuraManagerProjecUpdate,
        name: 'ProfilesSakuraManagerProjecUpdate'
    },
    {
        path: '/profilessakuramanager/chat',
        component: ProfilesSakuraManagerChat,
        name: 'ProfilesSakuraManagerChat'
    },
    {
        path: '/profilessakuramanager/chat-message',
        component: ProfilesSakuraManagerChatMessage,
        name: 'ProfilesSakuraManagerChatMessage'
    },
    {
        path: '/profilessakuramanager/chat-message2',
        component: ProfilesSakuraManagerChatMessage2,
        name: 'ProfilesSakuraManagerChatMessage2'
    },
    {
        path: '/profileinvestorsteiner/contacts',
        component: ProfileInvestorContacts,
        name: 'ProfileInvestorContacts'
    },
    {
        path: '/profileinvestorsteiner/detail-user',
        component: ProfileInvestorDetailUser,
        name: 'ProfileInvestorDetailUser'
    },
    {
        path: '/profileinvestorsteiner/signin',
        component: ProfileInvestorSignin,
        name: 'ProfileInvestorSignin'
    },
    {
        path: '/profileinvestorsteiner/code',
        component: ProfileInvestorCode,
        name: 'ProfileInvestorCode'
    },
    {
        path: '/profileinvestorsteiner/home',
        component: ProfileInvestorHome,
        name: 'ProfileInvestorHome'
    },
    {
        path: '/profileinvestorsteiner/alerts',
        component: ProfileInvestorAlerts,
        name: 'ProfileInvestorAlerts'
    },
    {
        path: '/profileinvestorsteiner/alerts2',
        component: ProfileInvestorAlerts2,
        name: 'ProfileInvestorAlerts2'
    },
    {
        path: '/profileinvestorsteiner/list-contacts',
        component: ProfileInvestorListContacts,
        name: 'ProfileInvestorListContacts'
    },
    {
        path: '/profileinvestorsteiner/chat',
        component: ProfileInvestorChat,
        name: 'ProfileInvestorChat'
    },
    {
        path: '/profileinvestorsteiner/register',
        component: ProfileInvestorRegister,
        name: 'ProfileInvestorRegister'
    },
    {
        path: '/profileinvestorsteiner/register2',
        component: ProfileInvestorRegister2,
        name: 'ProfileInvestorRegister2'
    },
    {
        path: '/profileinvestorsteiner/view-looking',
        component: ProfileInvestorLooking,
        name: 'ProfileInvestorLooking'
    },
    {
        path: '/profileinvestorsteiner/james',
        component: ProfileInvestorJames,
        name: 'ProfileInvestorJames'
    },
    {
        path: '/profileinvestorsteiner/investment-reservation',
        component: ProfileInvestorReservation,
        name: 'ProfileInvestorReservation'
    },
    {
        path: '/profileinvestorsteiner/select-other-user',
        component: ProfileInvestorSelectUser,
        name: 'ProfileInvestorSelectUser'
    }
]

