<template>
  <div class="pad-nav-bottom">
    <NavInvestor></NavInvestor>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="12" align-self="center">
            <a
              v-bind:href="'#/profileinvestorsteiner/register2'"
              class="float-left link-back"
            >
              <img src="../assets/img/icon-arrow-left-white.svg" class="mr-3 pulse-white" />
            </a>
            <h3 class="title-heder-user text-left mt-1">
              <b>Right Space:</b> Looking at the Sky
            </h3>
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container class="mt-4 mb-1">
          <b-row class="mt-2 mb-2">
            <b-col cols="12">
              <div class="header-detail-project text-left">
                <p class="title">Investment Documents</p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col cols="12">
              <div class="header-detail-project text-left border-bottom">
                <p class="subtitle">Idea</p>
                <p class="title">Looking at the Sky</p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="wrapper fadeInDown pb-4 mt-2">
                <p class="font-size-14 text-left text-gray mt-3">
                  Investment proposal
                </p>
                <ul class="text-left list-purple">
                  <li>
                    The <b>Looking at the Sky</b> project has an estimated
                    budget of <b>$3,725,000.</b>
                  </li>
                </ul>
                <p class="text-left font-size-12">
                  The tour is booked for 55 concerts between USA, Latin America,
                  Europe and we are working to include Asia. The duration is 3
                  years.
                </p>
                <ul class="text-left list-purple">
                  <li>
                    The show will premiere on einStream with an all-star live
                    event at Madison Square Garden, NYC and will be available
                    for indefinite viewing for a $0.99 fee.
                  </li>
                  <li>
                    We estimate viewing from 1.5 million views in the first year
                    to 3,300,000 in the third and final year of operation.
                  </li>
                  <li>
                    The estimated income of the project in 4 years (1 year of
                    production and 3 years of tour) is $7,500,000, merchandising
                    and royalties aside.
                  </li>
                  <li>
                    To facilitate the understanding of the idea you can see the
                    project file and the idea's pre-layout.
                  </li>
                  <li>
                    <b
                      >We are looking to cover 80% of the production investment,
                      2,980,000 with external capital investment.</b
                    >
                  </li>
                </ul>
                <p class="text-left font-size-12">
                  The 20%, the $745,000 we have available, is made up of the
                  following contributions.
                </p>
                <div class="w-100 text-center mt-4">
                  <img src="../assets/img/graphic-11-15-IS.svg" />
                </div>
                <b-row
                  id="list-transaction"
                  class="mt-5 mb-4 padd-special-list"
                >
                  <b-col>
                    <b-list-group>
                      <b-list-group-item>
                        <p class="w-100 d-inline-block text-left font-size-12">
                          <img
                            src="../assets/img/circle-1.png"
                            class="icon-ab-left"
                          />
                          <span class="font-weight-bold d-block"
                            >Pedro Raul</span
                          >
                          <span class="font-weight-light text-gray"
                            >Composer</span
                          >
                          <span
                            class="
                              date-text
                              float-right
                              date-transaction
                              position-absolute-right
                            "
                            >30%<b class="ml-3 text-dark">$223,200</b></span
                          >
                        </p>
                      </b-list-group-item>
                      <b-list-group-item>
                        <p class="w-100 d-inline-block text-left font-size-12">
                          <img
                            src="../assets/img/circle-2.png"
                            class="icon-ab-left"
                          />
                          <span class="font-weight-bold d-block"
                            >Juncal LC</span
                          >
                          <span class="font-weight-light text-gray"
                            >Dance Company</span
                          >
                          <span
                            class="
                              date-text
                              float-right
                              date-transaction
                              position-absolute-right
                            "
                            >35%<b class="ml-3 text-dark">$252,080</b></span
                          >
                        </p>
                      </b-list-group-item>
                      <b-list-group-item>
                        <p class="w-100 d-inline-block text-left font-size-12">
                          <img
                            src="../assets/img/circle-3.png"
                            class="icon-ab-left"
                          />
                          <span class="font-weight-bold d-block"
                            >Producer James</span
                          >
                          <span class="font-weight-light text-gray"
                            >Producer</span
                          >
                          <span
                            class="
                              date-text
                              float-right
                              date-transaction
                              position-absolute-right
                            "
                            >21%<b class="ml-3 text-dark">$157,200</b></span
                          >
                        </p>
                      </b-list-group-item>
                      <b-list-group-item>
                        <p class="w-100 d-inline-block text-left font-size-12">
                          <img
                            src="../assets/img/circle-4.png"
                            class="icon-ab-left"
                          />
                          <span class="font-weight-bold d-block"
                            >Rompeolas LLC</span
                          >
                          <span class="font-weight-light text-gray"
                            >Multidisciplinary production</span
                          >
                          <span
                            class="
                              date-text
                              float-right
                              date-transaction
                              position-absolute-right
                            "
                            >15%<b class="ml-3 text-dark">$112,800</b></span
                          >
                        </p>
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                </b-row>
                <p class="text-left font-size-12">
                  *This money has been invested in the idea and pre-production.
                </p>
                <ul class="text-left list-purple">
                  <li>
                    <b>Production investment packages</b><br /><br />
                    a) $500,000 for 4 years, first year grace period, remaining
                    20% interest + 15% royalties.<br /><br />

                    b) $250,000 for 4 years, first year of grace period,
                    remaining 18% interest + 7% royalties.<br /><br />

                    c) $125,000 for 4 years, first year grace period, remaining
                    15% interest + 3% royalties. <br /><br />

                    d) Customized with a specific agreement with Rompeolas, LLC
                    executive producer represented by Producer James.
                  </li>
                </ul>
                <p class="text-left font-size-12">
                  With this project we will produce a large amount of NFTs and
                  digital collector's items. This will not only bring in revenue
                  but will help promote the project with in the ein platform.
                </p>
                <div class="bg-purple-light p-3 mt-4 text-left mb-4">
                  <p class="color-purple font-size-12 mt-2">
                    <b>Note:</b> We are considering the realization of a
                    crowdfunding, in exchange for a signed DVD collector's
                    edition that will also serve to help promote Looking at the
                    Sky, in the networks.
                  </p>
                </div>
                <b-list-group id="menu-sidebar" class="text-left">
                  <b-list-group-item>
                    <img
                      src="../assets/img/icon-view-project.svg"
                      class="icon-nav mr-3"
                    />Download PDF<img
                      src="../assets/img/icon-arrow-right-black.svg"
                      class="arrow-right-register"
                    />
                  </b-list-group-item>
                  <b-list-group-item>
                    <img
                      src="../assets/img/icon-view-DEMO.svg"
                      class="icon-nav mr-3"
                    />View Demo<img
                      src="../assets/img/icon-arrow-right-black.svg"
                      class="arrow-right-register"
                    />
                  </b-list-group-item>
                </b-list-group>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-modal
          ref="close-project"
          id="close-project"
          centered
          hide-footer
          title="Confidentiality agreement"
        >
          <div class="d-block text-left">
            <p class="text">
              <b>
                To review Producer Jame’s project you must first sign his NDA.
              </b>
            </p>
            <p class="font-size-12">
              NDAs on ein exist to protect the ideas and productions for
              copyright reasons but also we help users set the rates of possible
              penalization’s.
            </p>
            <p class="font-size-12">
              Each user adjusts the clauses and penalties to hers or his likes.
            </p>
            <p class="text">
              <span
                class="
                  color-purple
                  text-underline text-uppercase
                  font-weight-bold
                "
                >Review the NDA</span
              >
            </p>
            <p class="font-size-12">
              If you want to contest or waive the NDA ask Producer James by
              clicking <a href="" class="text-black font-weight-bold">here</a>.
            </p>

            <p class="font-size-12">
              If you don’t accept to agree with the NDA the invitation will
              cancel automatically.
            </p>
          </div>
          <b-button class="mt-5 btn-purple-type-2-custom" block
            >Accept</b-button
          >
          <b-button class="mt-3 btn-dark-type-1-custom" block @click="hideModal"
            >Reject</b-button
          >
        </b-modal>
      </div>
    </div>
    <NavInvestorFooter></NavInvestorFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<style>
@import "../../node_modules/slither-slider/plugin-dist/slither-slider.css";
</style>
<script>
import NavInvestor from "../components/includes/NavInvestor.vue";
import NavInvestorFooter from "../components/includes/NavInvestorFooter.vue";
export default {
  data() {
    return {
      value: 30,
      options: [
        {
          text: "Grow your contacts with contacts of your contacts.",
          value: "1",
        },
        {
          text: "Allow ein to manage your space-time availability with access to your calendar.",
          value: "2",
        },
        {
          text: "Allow access to your location and enhance the coordination experience.",
          value: "3",
        },
      ],
    };
  },

  methods: {
    chooseFiles: function () {
      document.getElementById("fileUpload").click();
    },
    hideModal() {
      this.$refs["close-project"].hide();
    },
  },
  components: {
    NavInvestor,
    NavInvestorFooter,
  },
};
</script>

