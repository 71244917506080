<template>
  <div class="pad-nav-bottom">
    <NavAna></NavAna>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="10" align-self="center">
            <h3 class="subtitle-detail-alert text-left">
             <b>Project:</b>
              Looking at the Sky<br />
              <span class="mt-2 d-inline-block">Negotiation with Producer James</span>
            </h3>
          </b-col>
          <b-col cols="2" align-self="center">
            <img
              id="show-btn"
              @click="$bvModal.show('actions-detail-alert')"
              src="../assets/img/icon-header-more.svg"
              class="icon-menu-footer float-right"
            />
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row class="mt-2 mb-1">
            <b-col cols="12">
              <div class="header-detail-project text-left mb-1">
                <p class="font-black-16-bold mt-3">Agreement</p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-3">
            <b-col cols="12">
              <p class="font-black-12 mt-2 text-left">
                The agreement has been signed by both parties and is now active.
                You will find in your inbox a signed copy.
              </p>
              <p class="font-black-12 mt-4 text-left">
                The dates have been blocked in all of the involved calendars.
              </p>
            </b-col>
          </b-row>

          <b-row class="mt-3 pt-1 pb-1 mb-1">
            <b-col cols="12">
                 <a v-bind:href="'#/profile/select-user'">
              <b-button
                class="mt-2 btn-purple-type-1-custom pulse"
                block
              >
                Go to calendar
              </b-button>
              </a>
            </b-col>
          </b-row>
        </b-container>
        <b-modal
          ref="close-project"
          id="close-project"
          centered
          hide-footer
          title="Notification"
        >
          <div class="d-block text-center">
            <p class="text text-center mb-5">
              <b>Your email has been sent.</b><br /><br />
              If there is no answer with in 7 days and the guest Ana Artist
              hasn’t been registered on ein we will send you both a new notice.
            </p>
          </div>
          <a v-bind:href="'#/negotiation/messages'">
            <b-button class="mt-2 btn-purple-type-2-custom" block
              >Continue</b-button
            >
          </a>
        </b-modal>
      </div>
    </div>
    <NavAnaFooter></NavAnaFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
import NavAna from "../components/includes/NavAna.vue";
import NavAnaFooter from "../components/includes/NavAnaFooter.vue";
export default {
  methods: {
    hideModal() {
      this.$refs["close-project"].hide();
    },
  },
  data() {
    return {
      selected: [], // Must be an array reference!
      options: [
        { text: "You have worked together on ein in the past.", value: "1" },
        {
          text: "I want ein to recommend a possible collaborator.",
          value: "2",
        },
        {
          text:
            "I know someone that would like to be on ein that I can contact directly.",
          value: "3",
        },
      ],
      value1: 75,
      value2: 100,
      value3: 20,
    };
  },
  components: {
     NavAna,
    NavAnaFooter
  },
};
</script>

