<template>
  <div>
    <div class="pt-2 bg-white">
      <div class="bg-white border-top-left-right">
        <b-container>
          <b-row class="mt-0 mb-3">
            <b-col cols="12" align-self="center">
              <div id="search-invitation" class="mb-2 mt-4">
                <img
                  src="../assets/img/img-profile-ana-artist.png"
                  class="img-input-search"
                />
                <b-input-group-prepend is-text>
                  <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  type="search"
                  class="search-input"
                  placeholder="Search"
                >
                </b-form-input>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-3 mb-3">
            <b-col cols="12">
              <div class="content-title-invitation">
                <h2>
                  Do you want to collaborate…
                  <span class="tag-name">Inbox <b-icon icon="x"></b-icon></span>
                </h2>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-3 mb-3">
            <b-col cols="8 border-bottom">
              <div class="header-invitation-email text-left">
                <p class="text-left">
                  <img
                    src="../assets/img/img-profile-producer-james.png"
                    class="img-user-40"
                  />Producer James<br /><span
                    >to me <b-icon icon="chevron-down"></b-icon
                  ></span>
                </p>
              </div>
            </b-col>
            <b-col cols="4 text-right border-bottom">
              <b-icon icon="star-fill" class="mr-3"></b-icon>
              <b-icon icon="trash" class="mr-3"></b-icon>
              <b-icon icon="three-dots-vertical"></b-icon>
            </b-col>
          </b-row>
          <b-row class="mt-1 pt-1 pb-1 mb-1">
            <b-col id="text-content-email" cols="12">
              <hr class="mt-1" />
              <div class="bg-purple-light p-3 mt-4">
                <p>
                  Hi Ana, it's been a while. I'm working on a new project and
                  I'm looking for the best so I need you on guitar of course! We
                  are doing recordings and a world live tour.
                </p>
                <p>
                  Come check out all the details on my ein project and we can
                  discuss your pay.
                </p>
                <p>
                  I have set a 7 day timer in case I need to find someone else
                  in time.
                </p>
                <p>Best,</p>
                <p>
                  <span class="font-weight-bold">Producer James</span><br />
                  <span class="color-purple text-underline font-weight-bold"
                    >producerjames@somemail.com</span
                  ><br /><span class="text-underline">564.434.4234</span>
                </p>
              </div>
              <div class="bg-white p-3">
                <p>Hello Ana,</p>
                <p>
                  From ein we congratulate you because
                  <span class="font-weight-bold">Producer James</span> wants to
                  collaborate with you in a project.
                </p>
                <p>
                  To do so, you must register in ein, the project coordination
                  platform that will open the doors to new work and business
                  opportunities.
                </p>
                <p>
                  Once you have registered in ein you will have access to the
                  project information and you will be able to negotiate the
                  conditions through the ein chat.
                </p>
                <p>
                  You can download ein from
                  <span class="color-purple text-underline font-weight-bold"
                    >Apple's App Store</span
                  >
                  (IOS) or
                  <span class="color-purple text-underline font-weight-bold"
                    >Google's Play Store</span
                  >
                  (Android).
                </p>
                <p>
                  On the <span class="color-purple text-underline font-weight-bold">ein website</span> you will find more information about the
                  platform including all the information about the privacy
                  policy, use and protection of personal data.
                </p>
                <p>
                  In any case you should know that your data will only be used
                  for the proper functioning of the coordination mechanisms of
                  ein.
                </p>
                <p>We are waiting for you!</p>
                <p class="text-uppercase color-purple font-weight-bold">
                  Download the application, register and negotiate the
                  agreement.
                </p>
                <p>Yours sincerely</p>
                <p class="font-weight-bold">The ein Team</p>
              </div>
              <div class="text-left p-2 mb-3">
                <a v-bind:href="'#/profileana/welcome'">
                  <img
                    src="../assets/img/cta-download-google-play.svg"
                    class="mr-2 border-30px box-s-purple"
                  />
                </a>
                <a v-bind:href="'#/profileana/welcome'">
                  <img
                    v-bind:href="'#/profileana/welcome'"
                    src="../assets/img/cta-download-app-store.svg"
                    class="border-30px box-s-purple"
                  />
                </a>
              </div>
              <div class="bg-purple-light p-3">
                <p class="color-purple font-weight-bold text-14">
                  You have a maximum of 7 days to reply to this invitation but
                  you can register on ein at any time to discover the platforms
                  potential for you.
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-3 pt-1 pb-1 mb-3">
            <b-col cols="12">
              <hr />
              <div class="text-left actions-invitation-email">
                <b-button variant="outline-secondary" class="mr-4"
                  ><b-icon icon="reply"></b-icon> Reply</b-button
                >
                <b-button variant="outline-secondary" class=""
                  ><b-icon icon="skip-forward"></b-icon> Forward</b-button
                >
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-modal
          ref="close-project"
          id="close-project"
          centered
          hide-footer
          title="Notification"
        >
          <div class="d-block text-center">
            <p class="text text-center mb-5">
              <b>Your email has been sent.</b><br /><br />
              If there is no answer with in 7 days and the guest Ana Artist
              hasn’t been registered on ein we will send you both a new notice.
            </p>
          </div>
          <a v-bind:href="'#/negotiation/messages'">
            <b-button class="mt-2 btn-purple-type-2-custom" block
              >Continue</b-button
            >
          </a>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
export default {
  methods: {
    hideModal() {
      this.$refs["close-project"].hide();
    },
  },
  data() {
    return {
      selected: [], // Must be an array reference!
      options: [
        { text: "You have worked together on ein in the past.", value: "1" },
        {
          text: "I want ein to recommend a possible collaborator.",
          value: "2",
        },
        {
          text: "I know someone that would like to be on ein that I can contact directly.",
          value: "3",
        },
      ],
      value1: 75,
      value2: 100,
      value3: 20,
    };
  },
  components: {},
};
</script>

