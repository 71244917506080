<template>
  <div class="pt-5 bg-purple">
    <div class="padding-general">
      <b-container>
        <b-row class="mb-4">
          <b-col class="text-left text-white">
            <h3 class="title-h3-white">Choose an ein user to begin the demo</h3>
            <p class="text-white-14 mt-2">
              You’ll come back to this home page to choose another when you get
              to the end
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="bg-white border-top-left-right padding-general">
      <b-container>
        <b-row class="mt-5 mb-4">
          <b-col>
            <div class="wrapper fadeInDown">
                <b-col id="list-contacts" cols="12">
            <b-list-group>
              <b-list-group-item class="text-left w-100 opacity-50">
                <b-avatar class="mr-3">
                  <img
                    src="../assets/img/image-profile-general-producer-james.png"
                  />
                </b-avatar>
                <span class="text-left title-contact">
                  Producer James<br />
                  <span class="font-size-14 font-weight-300 color-purple"
                    >ein user</span
                  >
                </span>
                <img
                  src="../assets/img/icon-arrow-right-black.svg"
                  class="shadow-purple float-right icon-change"
                />
              </b-list-group-item>
              <b-list-group-item class="text-left opacity-50 w-100">
                <b-avatar class="mr-3">
                  <img
                    src="../assets/img/image-profile-general-ana-artist.png"
                  />
                </b-avatar>
                <span class="text-left title-contact">
                  Ana Artist<br />
                  <span class="font-size-14 font-weight-300 color-purple"
                    >ein user</span
                  >
                </span>
                <img
                  src="../assets/img/icon-arrow-right-black.svg"
                  class="shadow-purple float-right icon-change"
                />
              </b-list-group-item>
              <b-list-group-item class="text-left opacity-50 w-100">
                <b-avatar class="mr-3">
                  <img
                    src="../assets/img/image-profile-general-sakura-manager.png"
                  />
                </b-avatar>
                <span class="text-left title-contact">
                  Sakura Manager<br />
                  <span class="font-size-14 font-weight-300 color-purple"
                    >ein user</span
                  >
                </span>
                <img
                  src="../assets/img/icon-arrow-right-black.svg"
                  class="shadow-purple float-right icon-change"
                />
              </b-list-group-item>
              <b-list-group-item
                v-bind:href="'#/profileinvestorsteiner/detail-user'"
                class="text-left contacts-modal w-100"
              >
                <b-avatar class="mr-3">
                  <img
                    src="../assets/img/image-profile-general-investor-steiner.png"
                  />
                </b-avatar>
                <span class="text-left title-contact">
                  Investor Steiner<br />
                  <span class="font-size-14 font-weight-300 color-purple"
                    >ein user</span
                  >
                </span>
                <img
                  src="../assets/img/icon-arrow-right-black.svg"
                  class="shadow-purple float-right icon-change"
                />
              </b-list-group-item>
            </b-list-group>
          </b-col>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
export default {
  data() {
    return {
      password: "",
      password_type: "password",
      password_toggle_element: "show",
    };
  },

  methods: {
    togglePassword() {
      this.password_type =
        this.password_type === "password" ? "text" : "password";
    },
  },
};
</script>

