<template>
  <div class="pad-nav-bottom">
    <NavAna></NavAna>
    <div class="pt-5 bg-purple">
      <div class="bg-white border-top-left-right padding-general">
        <b-container class="mt-5 mb-1">
          <b-row class="mb-3">
            <b-col>
              <h2 class="title-header">
                Register<br />
                <span class="text-light">Payment method</span>
              </h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="wrapper fadeInDown pb-1 mt-3">
                <p class="font-black-12 mt-2 text-left">
                  To be able to receive and make payments and overall transact
                  professionally on ein you must introduce at least one
                  card/account. You can always stay on as an amateur.
                </p>
                <p class="text-left">
                  <a href="" class="pointer-events-none">
                    <span
                      class="
                        color-purple
                        text-underline
                        font-size-12 font-weight-bold
                      "
                      >VIEW SECURITY AND PRIVACY STATEMENTS</span
                    >
                  </a>
                </p>
                <p class="text-left text-red font-size-12">
                  No card has been introduced
                </p>
                <b-row id="content-tabs" class="tabs-payment mt-3 mb-2">
                  <b-col cols="12 ">
                    <b-card no-body>
                      <b-tabs pills card>
                        <b-tab title="Add card" active>
                          <b-card-text>
                            <p
                              class="
                                text-left
                                font-size-12 font-weight-bold
                                border-bottom
                                pb-3
                                pt-3
                              "
                            >
                              1. Select a card
                            </p>
                            <b-form-radio-group
                              id="rs"
                              label="Individual radios"
                              v-slot="{ ariaDescribedby }"
                            >
                              <b-form-radio
                                v-model="selected"
                                :aria-describedby="ariaDescribedby"
                                name="some-radios"
                                value="A"
                                class="style-radio"
                                ><img
                                  src="../assets/img/icon-register-visa.svg"
                              /></b-form-radio>
                              <b-form-radio
                                v-model="selected"
                                :aria-describedby="ariaDescribedby"
                                name="some-radios"
                                value="B"
                                ><img
                                  src="../assets/img/icon-register-Mastercard.svg"
                              /></b-form-radio>
                              <b-form-radio
                                v-model="selected"
                                :aria-describedby="ariaDescribedby"
                                name="some-radios"
                                value="C"
                                ><img
                                  src="../assets/img/icon-register-pay-pal.svg"
                              /></b-form-radio>
                            </b-form-radio-group>
                            <p
                              class="
                                text-left
                                font-size-12 font-weight-bold
                                border-bottom
                                pb-3
                                pt-3
                              "
                            >
                              2. Fill in the details
                            </p>
                            <b-row id="data-card">
                              <b-col cols="8" class="text-left">
                                <label for="input-live">CARD NUMBER</label>
                                <b-form-input
                                  id="cardnumber"
                                  v-model="cardnumber"
                                ></b-form-input>
                              </b-col>
                              <b-col cols="4" class="text-left">
                                <label for="input-live">CVC</label>
                                <b-form-input
                                  id="cvc"
                                  v-model="cvc"
                                ></b-form-input>
                              </b-col>
                              <b-col cols="12" class="text-left mt-2">
                                <label for="input-live">HOLDER NAME</label>
                                <b-form-input
                                  id="cardnumber"
                                  v-model="cardnumber"
                                ></b-form-input>
                              </b-col>
                              <b-col cols="12" class="text-left mt-2">
                                <label for="input-live">EXPIRATION DATE</label>
                              </b-col>

                              <b-col cols="4" class="text-left">
                                <b-form-select v-model="selected" class="mb-3">
                                  <b-form-select-option :value="null"
                                    >MM</b-form-select-option
                                  >
                                  <b-form-select-option value="a"
                                    >01</b-form-select-option
                                  >
                                  <b-form-select-option value="b" disabled
                                    >02</b-form-select-option
                                  >
                                </b-form-select>
                              </b-col>
                              <b-col cols="8" class="text-left">
                                <b-form-select v-model="selected" class="mb-3">
                                  <b-form-select-option :value="null"
                                    >YYYY</b-form-select-option
                                  >
                                  <b-form-select-option value="a"
                                    >2020</b-form-select-option
                                  >
                                  <b-form-select-option value="b" disabled
                                    >2021</b-form-select-option
                                  >
                                </b-form-select>
                              </b-col>
                            </b-row>
                            <b-row class="mt-4">
                              <b-col>
                                <b-form-group
                                  label=""
                                  v-slot="{ ariaDescribedby }"
                                  class="text-left"
                                >
                                  <b-form-checkbox
                                    size="lg"
                                    v-for="option in options"
                                    v-model="selected"
                                    :key="option.value"
                                    :value="option.value"
                                    :aria-describedby="ariaDescribedby"
                                    name="flavour-4a"
                                    inline
                                  >
                                    {{ option.text }}
                                  </b-form-checkbox>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-card-text>
                        </b-tab>
                        <b-tab title="Manage accounts"> </b-tab>
                      </b-tabs>
                    </b-card>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-1 mb-1">
            <b-col>
              <b-button
                @click="$bvModal.show('close-project')"
                block
                class="btn-purple-type-1-custom pulse"
                >Accept</b-button
              >

              <a v-bind:href="'#/profileana/register-ein-setup'">
                <b-button block class="btn-dark-type-1-custom mt-3"
                  >Cancel</b-button
                >
              </a>
            </b-col>
          </b-row>
        </b-container>
        <b-modal
          ref="close-project"
          id="close-project"
          centered
          hide-footer
          title="Verify"
        >
          <b-list-group id="resumen-card" class="text-left mb-4">
            <b-list-group-item>
              <p><b>Card</b> <span class="float-right text-gray">Visa</span></p>
            </b-list-group-item>
            <b-list-group-item>
              <p><b>Name</b> <span class="float-right text-gray">Ana Artist</span></p>
            </b-list-group-item>
            <b-list-group-item>
              <p>
                <b>Number</b>
                <span class="float-right text-gray">xxxx xxxx xxxx x462</span>
              </p>
            </b-list-group-item>
          </b-list-group>
          <p class="text text-left mb-4">
            To validate your card and account we will make a $0.01 transaction.
            Don’t worry you will get all this money back as soon as the account
            gets verified.
          </p>
          <p class="text text-left mb-5">
            Any errors will show up in the notification center so we can review
            what went wrong.
          </p>
          <a v-bind:href="'#/profileana/register-success'">
            <b-button block class="btn-purple-type-1-custom pulse">Save</b-button>
          </a>
          <b-button block class="btn-dark-type-1-custom mt-3">Modify</b-button>
        </b-modal>
      </div>
    </div>
    <NavAnaFooter></NavAnaFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<style>
@import "../../node_modules/slither-slider/plugin-dist/slither-slider.css";
</style>
<script>
import NavAna from "../components/includes/NavAna.vue";
import NavAnaFooter from "../components/includes/NavAnaFooter.vue";
export default {
  data() {
    return {
      value: 30,
      options: [
        {
          text: "Accept terms of service and I agree to conditions.",
          value: "1",
        },
        {
          text: "I’m not a robot",
          value: "2",
        },
      ],
    };
  },

  methods: {
    chooseFiles: function () {
      document.getElementById("fileUpload").click();
    },
    hideModal() {
      this.$refs["close-project"].hide();
    },
  },
  components: {
    NavAna,
    NavAnaFooter,
  },
};
</script>

