<template>
  <div class="pad-nav-bottom">
    <NavInvestor></NavInvestor>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="12" align-self="center">
            <h3 class="subtitle-h3-white text-left">
              Contacts

              <img
                src="../assets/img/icon-menu-alert.svg"
                class="icon-menu-footer float-right"
              />
              <img
                src="../assets/img/icon-search-white.svg"
                class="icon-menu-footer float-right mr-3"
              />
            </h3>
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row id="contacts-content" class="mt-3 mb-5">
            <b-col cols="12">
              <b-list-group>
                <b-list-group-item class="d-flex align-items-center">
                  <b-avatar class="mr-3">
                    <img
                      src="../assets/img/img-profile-perico-conesa.png"
                      class="icon_user"
                    />
                  </b-avatar>
                  <span class="text-left">
                    Conesa Pagés, Perico<br />
                    <small class="color-purple">Musician</small>
                  </span>
                  <img
                    src="../assets/img/icon-more-grey.svg"
                    class="icons-options pointer-events-none"
                    @click="$bvModal.show('actions-detail-alert')"
                  />
                </b-list-group-item>

                <b-list-group-item class="d-flex align-items-center">
                  <b-avatar class="mr-3">
                    <img
                      src="../assets/img/img-profile-sakura-manager.png"
                      class="icon_user"
                    />
                  </b-avatar>
                  <span class="text-left">
                    Sakura Manager<br />
                    <small class="color-purple">Musician</small>
                  </span>
                  <img
                    src="../assets/img/icon-more-grey.svg"
                    class="icons-options pulse"
                    @click="$bvModal.show('actions-detail-alert')"
                  />
                </b-list-group-item>

                <b-list-group-item class="d-flex align-items-center">
                  <b-avatar class="mr-3">
                    <img
                      src="../assets/img/img-profile-eva-fasa.png"
                      class="icon_user"
                    />
                  </b-avatar>
                  <span class="text-left">
                    Clorft, Raimunda<br />
                    <small class="color-purple">Musician</small>
                  </span>
                  <img
                    src="../assets/img/icon-more-grey.svg"
                    class="icons-options pointer-events-none"
                    @click="$bvModal.show('actions-detail-alert')"
                  />
                </b-list-group-item>

                <b-list-group-item class="d-flex align-items-center">
                  <b-avatar class="mr-3">
                    <img
                      src="../assets/img/img-profile-naomi-gilbert.png"
                      class="icon_user"
                    />
                  </b-avatar>
                  <span class="text-left">
                    Gilbert, Naomi<br />
                    <small class="color-purple">Musician</small>
                  </span>
                  <img
                    src="../assets/img/icon-more-grey.svg"
                    class="icons-options pointer-events-none"
                    @click="$bvModal.show('actions-detail-alert')"
                  />
                </b-list-group-item>

                <b-list-group-item class="d-flex align-items-center">
                  <b-avatar class="mr-3">
                    <img
                      src="../assets/img/img-profile-varela-nielsen.png"
                      class="icon_user"
                    />
                  </b-avatar>
                  <span class="text-left">
                    Varela, Nielsen<br />
                    <small class="color-purple">Musician</small>
                  </span>
                  <img
                    src="../assets/img/icon-more-grey.svg"
                    class="icons-options pointer-events-none"
                    @click="$bvModal.show('actions-detail-alert')"
                  />
                </b-list-group-item>

                <b-list-group-item class="d-flex align-items-center">
                  <b-avatar class="mr-3">
                    <img
                      src="../assets/img/img-profile-john-veiga.png"
                      class="icon_user"
                    />
                  </b-avatar>
                  <span class="text-left">
                    Veiga Albin, John<br />
                    <small class="color-purple">Musician</small>
                  </span>
                  <img
                    src="../assets/img/icon-more-grey.svg"
                    class="icons-options pointer-events-none"
                    @click="$bvModal.show('actions-detail-alert')"
                  />
                </b-list-group-item>

                <b-list-group-item class="d-flex align-items-center">
                  <b-avatar class="mr-3">
                    <img
                      src="../assets/img/img-profile-angel-villasmil.png"
                      class="icon_user"
                    />
                  </b-avatar>
                  <span class="text-left">
                    Villasmil Pinela, Angel<br />
                    <small class="color-purple">Musician</small>
                  </span>
                  <img
                    src="../assets/img/icon-more-grey.svg"
                    class="icons-options pointer-events-none"
                    @click="$bvModal.show('actions-detail-alert')"
                  />
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-container>
        <b-modal id="actions-detail-alert" hide-footer>
          <template #modal-title> </template>
          <div class="d-block text-left padding-general">
            <p class="font-black-18 mb-5">
              <img
                src="../assets/img/icon-menu-view-ix.svg"
                class="icon-modal mr-2"
              />
              View ix
            </p>

            <p class="font-black-18 mb-5 pointer-events-none">
              <img
                src="../assets/img/icon-menu-view-profile.svg"
                class="icon-modal mr-2"
              />
              View Profile
            </p>

            <p v-b-modal.close-project class="font-black-18 mb-5 pointer-events-none">
              <img
                src="../assets/img/icon-menu-follow.svg"
                class="icon-modal mr-2"
              />
              Follow his achievements
            </p>
            <a v-bind:href="'#/profileinvestorsteiner/chat'">
              <p v-b-modal.close-project class="font-black-18 mb-5 text-s-purple">
                <img
                  src="../assets/img/icon-menu-open-chat.svg"
                  class="icon-modal mr-2"
                />
                Open a chat
              </p>
            </a>
          </div>
        </b-modal>
        <b-modal
          ref="close-project"
          id="close-project"
          centered
          hide-footer
          title="Notification"
        >
          <div class="d-block text-center">
            <p class="text">
              To close the project you need to make sure all the fields you<br />
              projected are filed:
            </p>
            <p class="text-small text-left mb-5">
              <img
                src="../assets/img/icon-close-project-hover.svg"
                class="icon-modal mr-2"
              />Lead guitarist is missing.
            </p>
          </div>
          <b-button class="mt-3 btn-dark-type-1-custom" block @click="hideModal"
            >Cancelar</b-button
          >
          <b-button class="mt-2 btn-purple-type-2-custom" block
            >Go to Project Details</b-button
          >
        </b-modal>
      </div>
    </div>
    <NavInvestorFooter></NavInvestorFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
import NavInvestor from "../components/includes/NavInvestor.vue";
import NavInvestorFooter from "../components/includes/NavInvestorFooter.vue";
export default {
  methods: {
    hideModal() {
      this.$refs["close-project"].hide();
    },
  },
  data() {
    return {
      selected: [], // Must be an array reference!
      options: [
        { text: "You have worked together on ein in the past.", value: "1" },
        {
          text: "I want ein to recommend a possible collaborator.",
          value: "2",
        },
        {
          text: "I know someone that would like to be on ein that I can contact directly.",
          value: "3",
        },
      ],
      value1: 75,
      value2: 100,
      value3: 20,
    };
  },
  components: {
    NavInvestor,
    NavInvestorFooter,
  },
};
</script>

