<template>
  <div class="pad-nav-bottom">
    <Nav></Nav>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="10" align-self="center">
            <h3 class="subtitle-detail-alert text-left">
              Pre Production:<br />
              <b>Looking at the Sky</b><br />
              <span class="mt-2 d-inline-block"
                >Edit / <b>Find Co-worker</b></span
              >
            </h3>
          </b-col>
          <b-col cols="2" align-self="center">
            <img
              id="show-btn"
              @click="$bvModal.show('actions-detail-alert')"
              src="../assets/img/icon-ein-menu-more.svg"
              class="icon-menu-footer float-right pointer-events-none"
            />
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row class="mt-2 mb-1">
            <b-col cols="12">
              <div class="header-detail-project text-left">
                <p class="font-black-16-bold mt-4">Contacts</p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-1 mb-2">
            <b-col cols="12"> </b-col>
          </b-row>
          <b-row id="content-tabs" class="mt-3 mb-2">
            <b-col cols="12 ">
              <b-card no-body>
                <b-tabs id="filter-s" pills card>
                  <b-tab title="Search" active>
                    <b-card-text>
                      <b-input-group
                        id="search-home"
                        size="sm"
                        class="mb-2 mt-4"
                      >
                        <b-form-input
                          type="search"
                          class="search-input"
                          placeholder="Name, company, …"
                        >
                        </b-form-input>
                        <b-input-group-prepend is-text>
                          <b-icon icon="search"></b-icon>
                        </b-input-group-prepend>
                      </b-input-group>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Filtered search">
                    <b-form-group label="" class="text-left mt-3">
                      <b-form-checkbox size="lg"
                        >You have worked together on ein in the
                        past.</b-form-checkbox
                      >
                      <b-form-checkbox size="lg"
                        >I want ein to recommend a possible
                        collaborator.</b-form-checkbox
                      >
                      <b-form-checkbox size="lg" class="bx-shadow"
                        >I know someone that would like to be on ein that I can
                        contact directly.</b-form-checkbox
                      >
                    </b-form-group>
                  </b-tab>
                </b-tabs>
              </b-card>
              <b-row class="mt-4 pt-1 pb-1 mb-1">
                <b-col cols="12">
                  <a v-bind:href="'#/collaborators/YourPhoneContacts'">
                    <b-button class="mt-2 btn-purple-type-2-custom pulse" block>
                      Search
                    </b-button>
                  </a>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
        <b-modal id="actions-detail-alert" hide-footer>
          <template #modal-title> </template>
          <div class="d-block text-left padding-general">
            <a v-bind:href="'#/home/alerts/detail'">
              <p class="font-black-18 mb-5">
                <img
                  src="../assets/img/icon-ein-view-password-grey.svg"
                  class="icon-modal mr-2"
                />
                Review project
              </p>
            </a>
            <a v-bind:href="'#/home/alerts/detail'">
              <p class="font-black-18 mb-5">
                <img
                  src="../assets/img/icon-edit.svg"
                  class="icon-modal mr-2"
                />
                Edit project
              </p>
            </a>
            <p v-b-modal.close-project class="font-black-18 mb-5">
              <img
                src="../assets/img/icon-close-project.svg"
                class="icon-modal mr-2"
              />
              Close project
            </p>
            <div class="text-center">
              <img
                src="../assets/img/icon-arrow-down-purple.svg"
                class="mx-auto"
              />
            </div>
          </div>
        </b-modal>
        <b-modal
          ref="close-project"
          id="close-project"
          centered
          hide-footer
          title="Notification"
        >
          <div class="d-block text-center">
            <p class="text">
              To close the project you need to make sure all the fields you<br />
              projected are filed:
            </p>
            <p class="text-small text-left mb-5">
              <img
                src="../assets/img/icon-close-project-hover.svg"
                class="icon-modal mr-2"
              />Lead guitarist is missing.
            </p>
          </div>
          <b-button class="mt-3 btn-dark-type-1-custom" block @click="hideModal"
            >Cancelar</b-button
          >
          <b-button class="mt-2 btn-purple-type-2-custom" block
            >Go to Project Details</b-button
          >
        </b-modal>
      </div>
    </div>
    <NavFooter></NavFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
.pad-nav-bottom {
  padding-bottom: 130px;
}
#content-tabs {
  min-height: 250px;
}
input[name="flavour-4a"]::after {
  -webkit-box-shadow: 2px 3px 14px 1px rgb(255 255 255 / 78%);
  box-shadow: 2px 3px 14px 1px rgb(255 255 255 / 78%);
}
</style>
<script>
import Nav from "../components/includes/Nav.vue";
import NavFooter from "../components/includes/NavFooterNoPulse.vue";
export default {
  methods: {
    hideModal() {
      this.$refs["close-project"].hide();
    },
  },
  data() {
    return {
      selected: [], // Must be an array reference!
      options: [
        { text: "You have worked together on ein in the past.", value: "1" },
        {
          text: "I want ein to recommend a possible collaborator.",
          value: "2",
        },
        {
          class: "politicas",
          text: "I know someone that would like to be on ein that I can contact directly.",
          value: "3",
        },
      ],
      value1: 75,
      value2: 100,
      value3: 20,
    };
  },
  components: {
    Nav,
    NavFooter,
  },
};
</script>

