<template>
  <div class="pad-nav-bottom">
    <NavAna></NavAna>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="12" align-self="center">
            <h3 class="subtitle-h3-white text-left">
              <b>2</b> Notifications
              <a v-bind:href="'#/home'" class="pointer-events-none">
                <img
                  src="../assets/img/icon-menu-alert.svg"
                  class="icon-menu-footer float-right"
                />
              </a>
              <a v-bind:href="'#/home'" class="pointer-events-none">
                <img
                  src="../assets/img/icon-filter-alert.svg"
                  class="icon-menu-footer float-right mr-3"
                />
              </a>
            </h3>
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row id="according-content" class="mt-2 mb-2">
            <b-col cols="12">
              <div class="accordion" role="tablist">
                <!--New-->
                <b-card no-body class="mb-2">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div class="divider-left-green divider-absolute"></div>
                    <div class="header-according-link">
                    <a
                        v-bind:href="'#/profileana/negotiation-agreement'"
                        class="text-s-purple"
                      >
                        Agreement - P. James
                      </a>
                      <span class="date-text float-right">
                        12/08/2020
                        <img
                          src="../assets/img/icon-cross-alerts.svg"
                          class="icon-close ml-2 delete-item"
                        />
                      </span>
                    </div>
                    <h3 class="title-according text-left">
                      <span class="abstract-text-toogle"
                        >Signed the collaboration agreement…</span
                      >
                      <!-- replace these with your preferred icons -->
                      <img
                        v-b-toggle.accordion-1
                        src="../assets/img/icon-arrow-down-black.svg"
                        class="icon-close ml-2 mt-2 float-right"
                      />
                    </h3>
                  </b-card-header>
                  <b-collapse
                    id="accordion-1"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text>Pending entrees in project.</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <hr class="mt-1 mb-2" />
                <b-card no-body class="mb-2">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div class="divider-left-yellow divider-absolute"></div>
                    <div class="header-according-link">
                      <a
                        v-bind:href="'#/profileana/negotiation-agreement'"
                        class="pointer-events-none"
                      >
                        Confirmation - L. Mir
                      </a>
                      <span class="date-text float-right">
                        11/08/2020
                        <img
                          src="../assets/img/icon-cross-alerts.svg"
                          class="icon-close ml-2"
                        />
                      </span>
                    </div>
                    <h3 class="title-according text-left">
                      <span class="abstract-text-toogle"
                        >Accept your share of rights on ...</span
                      >
                      <!-- replace these with your preferred icons -->
                      <img
                        v-b-toggle.accordion-3
                        src="../assets/img/icon-arrow-down-black.svg"
                        class="icon-close ml-2 mt-2 float-right"
                      />
                    </h3>
                  </b-card-header>
                  <b-collapse
                    id="accordion-3"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text>{{ text }}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <hr class="mt-1 mb-2" />
                <!--New-->
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-modal
          ref="close-project"
          id="close-project"
          centered
          hide-footer
          title="Confidentiality agreement"
        >
          <p>
            <b
              >To review Producer Jame’s project you must first sign his NDA.</b
            >
          </p>
          <p class="text text-left">
            NDAs on ein exist to protect the ideas and productions for copyright
            reasons but also we help users set the rates of possible
            penalization’s.
          </p>
          <p class="text text-left">
            Each user adjusts the clauses and penalties to hers or his likes.
          </p>
          <p>
            <span
              class="color-purple text-underline font-weight-bold font-size-12"
              >REVIEW THE NDA</span
            >
          </p>
          <p class="text text-left">
            If you want to contest or waive the NDA ask Producer James by
            clicking <a href="" class="text-black text-underline"><b>here</b></a
            >.
          </p>
          <p class="text text-left">
            If you don’t accept to agree with the NDA the invitation will cancel
            automatically.
          </p>
          <b-button block class="btn-dark-type-1-custom mt-3 mb-2"
            >Reject</b-button
          >
          <a v-bind:href="'#/profileana/list-alert'">
            <b-button block class="btn-purple-type-1-custom">Accept</b-button>
          </a>
        </b-modal>
      </div>
    </div>
    <NavAnaFooter></NavAnaFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
import NavAna from "../components/includes/NavAna.vue";
import NavAnaFooter from "../components/includes/NavAnaFooter.vue";
export default {
  data() {
    return {
      text: '',
    };
  },
  components: {
    NavAna,
    NavAnaFooter,
  },
};
</script>

