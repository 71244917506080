<template>
  <div class="pt-5 bg-purple">
    <div class="bg-white border-top-left-right padding-general">
      <b-container class="mt-5 mb-1">
        <b-row class="mb-3">
          <b-col >
            <a
              v-bind:href="'#/profileana/welcome'"
              class="float-left link-back"
            >
              <img src="../assets/img/icon-arrow-left-black.svg" />
            </a>
            <h2 class="title-header">
              Register<br />
              <span class="text-light">User and password</span>
            </h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="wrapper fadeInDown">
              <div id="formContent">
                <!-- Login Form -->
                <form id="form-register" class="form-style">
                  <div class="form-group">
                    <label>User (email, phone)*</label><br />
                    <input
                      type="text"
                      id="user"
                      class="fadeIn second"
                      name="user"
                      label="user"
                      placeholder=""
                    />
                  </div>
                  <div class="form-group">
                    <label>Password</label><br />
                    <input
                      :type="password_type"
                      id="password"
                      class="fadeIn third "
                      name="login"
                      placeholder=""
                    />
                    <!--<b-progress
                      id="state-pass"
                      :value="value"
                      variant="danger"
                      class="mb-1 mt-2"
                    ></b-progress>
                    <p class="text-small-pb">Too weak</p>-->
                  </div>
                  <div class="form-group">
                    <label>Confirm Password</label><br />
                    <input
                      :type="password_type"
                      id="password"
                      class="fadeIn third"
                      name="login"
                      placeholder=""
                    />
                  </div>
                  <p class="h5 mb-2 text-right">
                    <b-icon @click="togglePassword()" icon="eye"></b-icon>
                  </p>
                  <div class="form-group mt-2 pb-4 policy-accept-text">
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="status"
                      name="checkbox-1"
                      value="accepted"
                      unchecked-value="not_accepted"
                    >
                      I accept ein’s privacy clauses and
                      <a href="">Therms</a> of agreement
                    </b-form-checkbox>
                  </div>
                </form>
                <b-row class="mt-4 mb-4">
                  <b-col>
                    <a v-bind:href="'#/profileana/register-image'">
                      <b-button block class="btn-purple-type-1-custom pulse"
                        >Continue</b-button
                      >
                    </a>
                    <a v-bind:href="'#/profileana/welcome'">
                      <b-button block class="btn-dark-type-1-custom mt-3"
                        >Cancel</b-button
                      >
                    </a>
                  </b-col>
                </b-row>
                <b-row class="text-center mt-3">
                  <b-col id="join-us">
                    <ul class="list-inline text-center">
                      <li class="list-inline-item">
                        <div class="circle-step active"></div>
                      </li>
                      <li class="list-inline-item">
                        <div class="circle-step"></div>
                      </li>
                    </ul>
                  </b-col>
                </b-row>
                <!-- Create Account -->
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
export default {
  data() {
    return {
      value: 30,
      password: "",
      password_type: "password",
      password_toggle_element: "show",
    };
  },

  methods: {
    togglePassword() {
      this.password_type =
        this.password_type === "password" ? "text" : "password";
    },
  },
};
</script>

