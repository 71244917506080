<template>
    <div class="pad-nav-bottom">
      <NavInvestor></NavInvestor>
      <div class="pt-2 bg-purple">
        <b-container>
          <b-row class="mt-2 mb-3 pl-10 pr-10">
            <b-col cols="12" align-self="center">
              <a
                v-bind:href="'#/profileinvestorsteiner/alerts'"
                class="float-left link-back"
              >
                <img
                  src="../assets/img/icon-arrow-left-white.svg"
                  class="mr-3"
                />
              </a>
              <h3 class="title-heder-user text-left mt-1">
                <b>Right Space:</b> Looking at the Sky
              </h3>
            </b-col>
          </b-row>
        </b-container>
        <div class="bg-white border-top-left-right padding-general">
          <b-container class="mt-4 mb-1">
            <b-row class="mt-2 mb-2">
              <b-col cols="12">
                <div class="header-detail-project text-left">
                  <p class="title">Investment Documents</p>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="wrapper fadeInDown pb-4 mt-2">
                  <b-list-group id="menu-sidebar" class="text-left">
                    <b-list-group-item>
                      <a v-bind:href="'#/profileinvestorsteiner/james'" class="pointer-events-none">
                        <img
                          src="../assets/img/icon-profile-james.svg"
                          class="icon-nav mr-3"
                        />
                        <a v-bind:href="'#/profileinvestorsteiner/james'"
                          >Producer James’s profile</a
                        ><img
                          src="../assets/img/icon-arrow-right-black.svg"
                          class="arrow-right-register"
                        />
                      </a>
                    </b-list-group-item>
                    <b-list-group-item @click="$bvModal.show('close-project')" class="pointer-events-none"
                      ><img
                        src="../assets/img/icon-view-project-p.svg"
                        class="icon-nav mr-3" />View “Looking at the Sky”<img
                        src="../assets/img/icon-arrow-right-black.svg"
                        class="arrow-right-register"
                    /></b-list-group-item>
                    <b-list-group-item class="pointer-events-none"
                      ><img
                        src="../assets/img/icon-investment-proposal.svg"
                        class="icon-nav mr-3" />Investment proposal<img
                        src="../assets/img/icon-arrow-right-black.svg"
                        class="arrow-right-register"
                    /></b-list-group-item>
                    <b-list-group-item
                      ><img
                        src="../assets/img/icon-investment-reservation.svg"
                        class="icon-nav mr-3" /><a href="#/profileinvestorsteiner/investment-reservation" class="text-s-purple"
                        >Investment reservation </a
                      ><img
                        src="../assets/img/icon-arrow-right-black.svg"
                        class="arrow-right-register"
                    /></b-list-group-item>
                  </b-list-group>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <b-modal
            ref="close-project"
            id="close-project"
            centered
            hide-footer
            title="Confidentiality agreement"
          >
            <div class="d-block text-left">
              <p class="text">
                <b>
                  To review Producer Jame’s project you must first sign his NDA.
                </b>
              </p>
              <p class="font-size-12">
                NDAs on ein exist to protect the ideas and productions for
                copyright reasons but also we help users set the rates of
                possible penalization’s.
              </p>
              <p class="font-size-12">
                Each user adjusts the clauses and penalties to hers or his
                likes.
              </p>
              <p class="text">
                <span
                  class="
                    color-purple
                    text-underline text-uppercase
                    font-weight-bold font-size-12
                  "
                  >Review the NDA</span
                >
              </p>
              <p class="font-size-12">
                If you want to contest or waive the NDA ask Producer James by
                clicking
                <a href="" class="text-black font-weight-bold">here</a>.
              </p>

              <p class="font-size-12">
                If you don’t accept to agree with the NDA the invitation will
                cancel automatically.
              </p>
            </div>
            <a href="#/profileinvestorsteiner/view-looking">
              <b-button class="mt-5 btn-purple-type-2-custom" block
                >Accept</b-button
              >
            </a>
            <b-button
              class="mt-3 btn-dark-type-1-custom"
              block
              @click="hideModal"
              >Reject</b-button
            >
          </b-modal>
        </div>
      </div>
      <NavInvestorFooter></NavInvestorFooter>
    </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<style>
@import "../../node_modules/slither-slider/plugin-dist/slither-slider.css";
</style>
<script>
import NavInvestor from "../components/includes/NavInvestor.vue";
import NavInvestorFooter from "../components/includes/NavInvestorFooter.vue";
export default {
  data() {
    return {
      value: 30,
      options: [
        {
          text: "Grow your contacts with contacts of your contacts.",
          value: "1",
        },
        {
          text: "Allow ein to manage your space-time availability with access to your calendar.",
          value: "2",
        },
        {
          text: "Allow access to your location and enhance the coordination experience.",
          value: "3",
        },
      ],
    };
  },

  methods: {
    chooseFiles: function () {
      document.getElementById("fileUpload").click();
    },
    hideModal() {
      this.$refs["close-project"].hide();
    },
  },
  components: {
    NavInvestor,
    NavInvestorFooter,
  },
};
</script>

