<template>
  <div class="pad-130">
    <Nav></Nav>
    <div class="pt-2 bg-purple">
        <b-container>
          <b-row class="mt-2 mb-3 pl-10 pr-10" align-self="center">
            <b-col class="text-left text-white">
              <a
                v-bind:href="'#/negotiation/transaction'"
                class="float-left link-back"
              >
                <img src="../assets/img/icon-arrow-left-white.svg" />
              </a>
              <h3 class="subtitle-h3-white">Back to transactions</h3>
            </b-col>
          </b-row>
        </b-container>

      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row class="mt-2 mb-1">
            <b-col cols="12">
              <div class="header-detail-project text-left">
                <p class="font-black-16-bold mt-4">
                  <span class="title-section-22">Details</span>
                 <img src="../assets/img/DownPdf.svg" class="float-right ml-2"/>
                 <span class="float-right text-download">Download PDF</span>
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row id="list-transaction" class="mt-2 mb-4">
            <b-col>
              <b-list-group>
                <b-list-group-item>
                  <p class="w-100 d-inline-block text-left">
                    <a
                      v-bind:href="'#/negotiation/detail-transaction'"
                      class="title-transaction"
                    >
                      Date
                    </a>
                    <span
                      class="
                        date-text
                        float-right
                        date-transaction
                        position-absolute-right
                      "
                      >20/09/2020</span
                    >
                  </p>
                </b-list-group-item>
                <b-list-group-item>
                  <p class="w-100 d-inline-block text-left">
                    <a
                      v-bind:href="'#/negotiation/detail-transaction'"
                      class="title-transaction"
                    >
                      Company
                    </a>
                    <span
                      class="
                        date-text
                        float-right
                        date-transaction
                        position-absolute-right
                      "
                      >Round House. LLC</span
                    >
                  </p>
                </b-list-group-item>
                <b-list-group-item>
                  <p class="w-100 d-inline-block text-left">
                    <a
                      v-bind:href="'#/negotiation/detail-transaction'"
                      class="title-transaction"
                    >
                      Beneficiary
                    </a>
                    <span
                      class="
                        date-text
                        float-right
                        date-transaction
                        position-absolute-right
                      "
                      >EIN, LLC</span
                    >
                  </p>
                </b-list-group-item>
                <b-list-group-item>
                  <p class="w-100 d-inline-block text-left">
                    <a
                      v-bind:href="'#/negotiation/detail-transaction'"
                      class="title-transaction"
                    >
                      Total
                    </a>
                    <span
                      class="date-text float-right price-transaction text-red"
                      >-$3.75</span
                    >
                  </p>
                </b-list-group-item>
                <b-list-group-item>
                  <p class="w-100 d-inline-block text-left">
                    <a
                      v-bind:href="'#/negotiation/detail-transaction'"
                      class="title-transaction"
                    >
                      Concept
                    </a>
                    <span
                      class="
                        date-text
                        float-right
                        date-transaction
                        position-absolute-right
                      "
                      >Transaction commission $12,500</span
                    >
                  </p>
                </b-list-group-item>
                <b-list-group-item>
                  <p class="w-100 d-inline-block text-left">
                    <a
                      v-bind:href="'#/negotiation/detail-transaction'"
                      class="title-transaction"
                    >
                      Reason
                    </a>
                    <span
                      class="
                        date-text
                        float-right
                        date-transaction
                        position-absolute-right
                      "
                      >Ana Artist, 30% booked artist<br />“Looking at the Sky”
                    </span>
                  </p>
                </b-list-group-item>
                <b-list-group-item>
                  <p class="w-100 d-inline-block text-left">
                    <a
                      v-bind:href="'#/negotiation/detail-transaction'"
                      class="title-transaction"
                    >
                      ein ID
                    </a>
                    <span
                      class="
                        date-text
                        float-right
                        price-transaction
                        color-purple
                      "
                      >6999874ED48
                    </span>
                  </p>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col>
              <b-button class="mt-2 btn-purple-type-1-custom" block
                >View details of transaction
              </b-button>
              <b-button
                class="mt-3 btn-dark-type-1-custom"
                block
                @click="hideModal"
                >Review Project</b-button
              >
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <NavFooter></NavFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
#list-transaction .list-group-item
{
  padding-left: 0px;
    padding-right: 0px;
}
#list-transaction .title-transaction {
    color: #101215;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 22px;
    text-shadow: 0 0 10px 0 #5E26DA;
}
</style>
<script>
import Nav from "../components/includes/Nav.vue";
import NavFooter from "../components/includes/NavFooterNoPulseCalendar.vue";
export default {
  data() {
    return {
      password: "",
      password_type: "password",
      password_toggle_element: "show",
    };
  },
  components: {
    Nav,
    NavFooter,
  },
  methods: {
    togglePassword() {
      this.password_type =
        this.password_type === "password" ? "text" : "password";
    },
  },
};
</script>

