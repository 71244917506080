<template>
  <div class="pad-nav-bottom">
    <NavAna></NavAna>
    <div class="pt-5 bg-purple">
      <div class="bg-white border-top-left-right padding-general mt-m-1">
        <b-container class="mt-5 mb-1">
          <b-row class="mb-3">
            <b-col>
              <h2 class="title-header">
                Register<br />
                <span class="text-light">ein Set Up</span>
              </h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="bg-purple-light p-3 mt-4">
                <h3 class="text-purple-bold">
                  You made it, now you are an ein member!
                </h3>
              </div>
              <div class="wrapper fadeInDown pb-4 mt-3">
                <p class="font-black-12 mt-2 text-left">Now you can:</p>
                <b-list-group id="menu-sidebar" class="text-left">
                  <b-list-group-item
                    ><img
                      src="../assets/img/icon-register-continue-set-up.svg"
                      class="icon-nav mr-3"
                    />
                    <a href="" class="pointer-events-none">Continue with the set up</a><img
                      src="../assets/img/icon-arrow-right-black.svg"
                      class="arrow-right-register"
                    /></b-list-group-item
                  >
                  <b-list-group-item
                    ><img
                      src="../assets/img/icon-register-go-home.svg"
                      class="icon-nav mr-3"
                    /><a href="" class="pointer-events-none">Go to my home</a><img
                      src="../assets/img/icon-arrow-right-black.svg"
                      class="arrow-right-register"
                    /></b-list-group-item
                  >
                  <b-list-group-item
                    ><img
                      src="../assets/img/icon-register-tour.svg"
                      class="icon-nav mr-3"
                    /><a href="" class="pointer-events-none">Tour to the ein platform</a><img
                      src="../assets/img/icon-arrow-right-black.svg"
                      class="arrow-right-register"
                    /></b-list-group-item
                  >
                </b-list-group>
                <p class="font-black-12 mt-5 text-left">
                    Remember that to be able to receive payments you must introduce your account or card details or PayPal.
                </p>
                 <p class="text-left">
                     <a v-bind:href="'#/profileana/register-payment'">
                  <span class="color-purple font-weight-bold font-size-14 text-s-purple"
                    >WOULD YOU LIKE TO DO THAT NOW?</span
                  >
                  </a>
                </p>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-modal
          ref="close-project"
          id="close-project"
          centered
          hide-footer
          title="ix"
        >
          <b-icon
            @click="$bvModal.hide('close-project')"
            icon="x-circle-fill"
            class="close-modal-level"
          ></b-icon>
          <slither-slider
            id="level"
            :options="{
              adaptiveHeight: true,
              autoplay: false,
              controls: false,
            }"
          >
            <!-- Slide 1 -->
            <div>
              <div class="d-block text-center modal-ix">
                <img src="../assets/img/icon-ix.png" />
                <h3>Level 0</h3>
                <p class="text text-center mb-5">
                  ix level zero means this ein user has: no skills, no ideas, no
                  projects, no products, no opportunities, no experience, no
                  earnings, no contacts, no engagement or very very few, less
                  than 1% of the global possible
                </p>
              </div>
              <a v-bind:href="'#/profileana/register-ein-setup'">
                <b-button class="mt-2 btn-purple-type-2-custom" block
                  >Continue</b-button
                >
              </a>
            </div>
            <!-- Slide 2-->
            <div>
              <div class="d-block text-center modal-ix">
                <img src="../assets/img/icon-ix.png" />
                <h3>Level 1</h3>
                <p class="text text-center mb-5">
                  ix level 1 means: the sum of this ein users’ skills, ideas,
                  productions, sales, opportunities, experience, contacts and
                  engagement adds up to a percentage number on the global scale
                  between 20 and 30%. Most users start around here.
                </p>
              </div>
              <a v-bind:href="'#/profileana/register-ein-setup'">
                <b-button class="mt-2 btn-purple-type-2-custom" block
                  >Continue</b-button
                >
              </a>
            </div>
            <!-- Slide 3-->
            <div>
              <div class="d-block text-center modal-ix">
                <img src="../assets/img/icon-ix.png" />
                <h3>Level 3</h3>
                <p class="text text-center mb-5">
                  Your ix level is a reliable way for other users to know more
                  accurately where you are situated relative to them on the ein
                  platform, which helps reduce information asymmetries. Users
                  with the same ix level connect very well and more often.
                </p>
              </div>
              <a v-bind:href="'#/profileana/register-ein-setup'">
                <b-button class="mt-2 btn-purple-type-2-custom" block
                  >Continue</b-button
                >
              </a>
            </div>
            <!-- Slide 4-->
            <div>
              <div class="d-block text-center modal-ix">
                <img src="../assets/img/icon-ix.png" />
                <h3>Level 4</h3>
                <p class="text text-center mb-5">
                  Your ix level helps you know where you are and where you can
                  go, it opens doors for you and it measures how you are
                  perceived beyond likes, views, plays and fol lowers, which are
                  unreliable numbers because those are bought and sold.
                </p>
              </div>
              <a v-bind:href="'#/profileana/register-ein-setup'">
                <b-button class="mt-2 btn-purple-type-2-custom" block
                  >Continue</b-button
                >
              </a>
            </div>
            <!-- Slide 5-->
            <div>
              <div class="d-block text-center modal-ix">
                <img src="../assets/img/icon-ix.png" />
                <h3>Level 5</h3>
                <p class="text text-center mb-5">
                  Your ix level helps you know where you are and where you can
                  go, it opens doors for you and it measures how you are
                  perceived beyond likes, views, plays and fol lowers, which are
                  unreliable numbers because those are bought and sold.
                </p>
              </div>
              <a v-bind:href="'#/profileana/register-ein-setup'">
                <b-button class="mt-2 btn-purple-type-2-custom" block
                  >Continue</b-button
                >
              </a>
            </div>
          </slither-slider>
        </b-modal>
      </div>
    </div>
     <NavAnaFooter></NavAnaFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<style>
@import "../../node_modules/slither-slider/plugin-dist/slither-slider.css";
</style>
<script>
import NavAna from "../components/includes/NavAna.vue";
import NavAnaFooter from "../components/includes/NavAnaFooter.vue";
export default {
  data() {
    return {
      value: 30,
      options: [
        {
          text: "Grow your contacts with contacts of your contacts.",
          value: "1",
        },
        {
          text:
            "Allow ein to manage your space-time availability with access to your calendar.",
          value: "2",
        },
        {
          text:
            "Allow access to your location and enhance the coordination experience.",
          value: "3",
        },
      ],
    };
  },

  methods: {
    chooseFiles: function () {
      document.getElementById("fileUpload").click();
    },
    hideModal() {
      this.$refs["close-project"].hide();
    },
  },
  components: {
    NavAna,
    NavAnaFooter
  },
};
</script>

