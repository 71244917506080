<template>
  <div class="pad-nav-bottom">
    <Nav></Nav>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="12" align-self="center">
            <h3 class="subtitle-h3-white text-left">
              <b>8</b> Notifications

              <img
                src="../assets/img/icon-menu-alert.svg"
                class="icon-menu-footer float-right"
              />

              <img
                src="../assets/img/icon-filter-alert.svg"
                class="icon-menu-footer float-right mr-3"
              />
            </h3>
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row id="according-content" class="mt-2 mb-2">
            <b-col cols="12">
              <div class="accordion" role="tablist">
                 <b-card no-body class="mb-2">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div class="divider-left-purple divider-absolute"></div>
                    <div class="header-according-link">
                       <p class="mb-0"><span class="alert-expired">Expires in 48hrs</span></p>
                      <a
                         v-bind:href="'#/negotiation/messages'"
                        class="title-according text-s-purple"
                      >
                         Work proposal - Ana Artist 
                      </a>
                      <span class="date-text float-right">
                        31/07/2020
                        <img
                          src="../assets/img/icon-cross-alerts.svg"
                          class="icon-close ml-2 delete-item"
                        />
                      </span>
                    </div>
                    <h3 class="title-according text-left">
                      <span class="abstract-text-toogle"
                        >Pending entrees in project.</span
                      >
                      <!-- replace these with your preferred icons -->
                      <img
                        v-b-toggle.accordion-7
                        src="../assets/img/icon-arrow-down-black.svg"
                        class="icon-close ml-2 mt-2 float-right pulse"
                      />
                    </h3>
                  </b-card-header>
                  <b-collapse
                    id="accordion-7"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text>text all project</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <hr class="mt-1 mb-2" />
                <b-card no-body class="mb-2">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div class="divider-left-yellow divider-absolute"></div>
                    <div class="header-according-link">
                      <a
                        v-bind:href="'#/home/alerts/detail'"
                        class="pointer-events-none title-according"
                      >
                        Call - Linda Robin
                      </a>
                      <span class="date-text float-right">
                        31/07/2020
                        <img
                          src="../assets/img/icon-cross-alerts.svg"
                          class="icon-close ml-2 delete-item"
                        />
                      </span>
                    </div>
                    <h3 class="title-according text-left">
                      <span class="abstract-text-toogle"
                        >Renegotiate contract</span
                      >
                      <!-- replace these with your preferred icons -->
                      <img
                        v-b-toggle.accordion-1
                        src="../assets/img/icon-arrow-down-black.svg"
                        class="icon-close ml-2 mt-2 float-right"
                      />
                    </h3>
                  </b-card-header>
                  <b-collapse
                    id="accordion-1"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text>{{ text }}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <hr class="mt-1 mb-2" />
                <b-card no-body class="mb-2">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div class="divider-left-red divider-absolute"></div>
                    <div class="header-according-link">
                      <a
                        v-bind:href="'#/home/alerts/detail'"
                        class="pointer-events-none"
                      >
                        Project - Looking at the…
                      </a>
                      <span class="date-text float-right">
                        18/07/2020
                        <img
                          src="../assets/img/icon-cross-alerts.svg"
                          class="icon-close ml-2 delete-item"
                        />
                      </span>
                    </div>
                    <h3 class="title-according text-left">
                      <span class="abstract-text-toogle"
                        >Pending entrees in project. Lorem…</span
                      >
                      <!-- replace these with your preferred icons -->
                      <img
                        v-b-toggle.accordion-2
                        src="../assets/img/icon-arrow-down-black.svg"
                        class="icon-close ml-2 mt-2 float-right"
                      />
                    </h3>
                  </b-card-header>
                  <b-collapse
                    id="accordion-2"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text>{{ text }}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <hr class="mt-1 mb-2" />
                <b-card no-body class="mb-2">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div class="divider-left-purple divider-absolute"></div>
                    <div class="header-according-link">
                      <a
                        v-bind:href="'#/home/alerts/detail'"
                        class="pointer-events-none title-according"
                      >
                        Supporting - Jamal Rashid
                      </a>
                      <span class="date-text float-right">
                        18/07/2020
                        <img
                          src="../assets/img/icon-cross-alerts.svg"
                          class="icon-close ml-2 delete-item"
                        />
                      </span>
                    </div>
                    <h3 class="title-according text-left">
                      <span class="abstract-text-toogle"
                        >Pending entrees in project.</span
                      >
                      <!-- replace these with your preferred icons -->
                      <img
                        v-b-toggle.accordion-3
                        src="../assets/img/icon-arrow-down-black.svg"
                        class="icon-close ml-2 mt-2 float-right"
                      />
                    </h3>
                  </b-card-header>
                  <b-collapse
                    id="accordion-3"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text>{{ text }}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <hr class="mt-1 mb-2" />
                <b-card no-body class="mb-2">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div class="divider-left-purple divider-absolute"></div>
                    <div class="header-according-link">
                      <a
                        v-bind:href="'#/home/alerts/detail'"
                        class="pointer-events-none title-according"
                      >
                        Info - Payment
                      </a>
                      <span class="date-text float-right">
                        09/07/2020
                        <img
                          src="../assets/img/icon-cross-alerts.svg"
                          class="icon-close ml-2 delete-item"
                        />
                      </span>
                    </div>
                    <h3 class="title-according text-left">
                      <span class="abstract-text-toogle">Update info</span>
                      <!-- replace these with your preferred icons -->
                      <img
                        v-b-toggle.accordion-4
                        src="../assets/img/icon-arrow-down-black.svg"
                        class="icon-close ml-2 mt-2 float-right"
                      />
                    </h3>
                  </b-card-header>
                  <b-collapse
                    id="accordion-4"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text>{{ text }}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <hr class="mt-1 mb-2" />
                <b-card no-body class="mb-2">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div class="divider-left-green divider-absolute"></div>
                    <div class="header-according-link">
                      <a
                        v-bind:href="'#/home/alerts/detail'"
                        class="pointer-events-none title-according"
                      >
                        Reminder - JVB
                      </a>
                      <span class="date-text float-right">
                        02/07/2020
                        <img
                          src="../assets/img/icon-cross-alerts.svg"
                          class="icon-close ml-2 delete-item"
                        />
                      </span>
                    </div>
                    <h3 class="title-according text-left">
                      <span class="abstract-text-toogle"
                        >Close agreement with Sabrina</span
                      >
                      <!-- replace these with your preferred icons -->
                      <img
                        v-b-toggle.accordion-5
                        src="../assets/img/icon-arrow-down-black.svg"
                        class="icon-close ml-2 mt-2 float-right"
                      />
                    </h3>
                  </b-card-header>
                  <b-collapse
                    id="accordion-5"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text>{{ text }}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <hr class="mt-1 mb-2" />
                <b-card no-body class="mb-2">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div class="divider-left-purple divider-absolute"></div>
                    <div class="header-according-link">
                      <a
                        v-bind:href="'#/home/alerts/detail'"
                        class="pointer-events-none title-according"
                      >
                        Project - Looking at the…
                      </a>
                      <span class="date-text float-right">
                        12/06/2020
                        <img
                          src="../assets/img/icon-cross-alerts.svg"
                          class="icon-close ml-2 delete-item"
                        />
                      </span>
                    </div>
                    <h3 class="title-according text-left">
                      <span class="abstract-text-toogle"
                        >You opened new stage in the project</span
                      >
                      <!-- replace these with your preferred icons -->
                      <img
                        v-b-toggle.accordion-6
                        src="../assets/img/icon-arrow-down-black.svg"
                        class="icon-close ml-2 mt-2 float-right"
                      />
                    </h3>
                  </b-card-header>
                  <b-collapse
                    id="accordion-6"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text>{{ text }}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <hr class="mt-1 mb-2" />
                <b-card no-body class="mb-2">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div class="divider-left-purple divider-absolute"></div>
                    <div class="header-according-link">
                      <a
                        v-bind:href="'#/home/alerts/detail'"
                        class="pointer-events-none title-according"
                      >
                        Offer - Catering
                      </a>
                      <span class="date-text float-right">
                        11/06/2020
                        <img
                          src="../assets/img/icon-cross-alerts.svg"
                          class="icon-close ml-2 delete-item"
                        />
                      </span>
                    </div>
                    <h3 class="title-according text-left">
                      <span class="abstract-text-toogle"
                        >New event looking for catering service</span
                      >
                      <!-- replace these with your preferred icons -->
                      <img
                        v-b-toggle.accordion-7
                        src="../assets/img/icon-arrow-down-black.svg"
                        class="icon-close ml-2 mt-2 float-right"
                      />
                    </h3>
                  </b-card-header>
                  <b-collapse
                    id="accordion-7"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text>{{ text }}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <!--new--->
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <NavFooter></NavFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
import Nav from "../components/includes/Nav.vue";
import NavFooter from "../components/includes/NavFooterNoPulse.vue";
export default {
  data() {
    return {
      text: '',
    };
  },
  components: {
    Nav,
    NavFooter,
  },
};
</script>

