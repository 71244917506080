<template>
  <div class="pad-nav-bottom">
    <NavSakura></NavSakura>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="12" align-self="center">
            <h3 class="subtitle-h3-white text-left">
              <b>Chat</b>

              <img
                src="../assets/img/icon-menu-alert.svg"
                class="icon-menu-footer float-right"
              />
              <img
                src="../assets/img/icon-search-white.svg"
                class="icon-menu-footer float-right mr-3"
              />
            </h3>
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row class="mt-0 mb-3">
            <b-col>
              <h3 class="title-section-list-chat mb-4 text-left mt-4">
                UNREAD
              </h3>
              <div class="list-chat mb-3">
                <a v-bind:href="'#/profilessakuramanager/chat-message'" class="link-no-hover">
                  <div class="item-user-chat">
                    <b-row>
                      <b-col cols="2">
                        <img
                          src="../assets/img/image-profile-chat-investor-steiner.png"
                          class="icon-user-chat"
                        />
                      </b-col>
                      <b-col cols="8" class="text-left text-s-purple">
                        <h5>Investor Steiner</h5>
                        <p>Hi Sakura, I want to ask you…</p>
                      </b-col>
                      <b-col cols="2">
                        <p class="date">23/12/12</p>
                        <b-badge pill class="bg-purple float-right">1</b-badge>
                      </b-col>
                    </b-row>
                  </div>
                </a>
              </div>
              <hr class="bg-gray" />
              <h3 class="title-section-list-chat mb-4 text-left mt-4">READ</h3>
              <div class="list-chat">
                <div class="item-user-chat mb-3">
                  <b-row>
                    <b-col cols="2">
                      <img
                        src="../assets/img/image-profile-chat-amber-raizzal.png"
                        class="icon-user-chat"
                      />
                    </b-col>
                    <b-col cols="8" class="text-left">
                      <h5>Amber Raizzal</h5>
                      <p>Saku, I want to be a part of…</p>
                    </b-col>
                    <b-col cols="2">
                      <p class="date">23/12/12</p>
                    </b-col>
                  </b-row>
                </div>
                <div class="item-user-chat mb-3">
                  <b-row>
                    <b-col cols="2">
                      <img
                        src="../assets/img/image-profile-chat-ana-artist.png"
                        class="icon-user-chat"
                      />
                    </b-col>
                    <b-col cols="8" class="text-left">
                      <h5>Ana Artist</h5>
                      <p>Sakura, I have an idea…</p>
                    </b-col>
                    <b-col cols="2">
                      <p class="date">23/12/12</p>
                    </b-col>
                  </b-row>
                </div>
                <div class="item-user-chat mb-3">
                  <b-row>
                    <b-col cols="2">
                      <img
                        src="../assets/img/image-profile-chat-mishani-mikako.png"
                        class="icon-user-chat"
                      />
                    </b-col>
                    <b-col cols="8" class="text-left">
                      <h5>Mishani Mikako</h5>
                      <p>Sra Sakura, we should close…</p>
                    </b-col>
                    <b-col cols="2">
                      <p class="date">23/12/12</p>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-modal
          ref="close-project"
          id="close-project"
          centered
          hide-footer
          title="Confidentiality agreement"
        >
          <p>
            <b
              >To review Producer Jame’s project you must first sign his NDA.</b
            >
          </p>
          <p class="text text-left">
            NDAs on ein exist to protect the ideas and productions for copyright
            reasons but also we help users set the rates of possible
            penalization’s.
          </p>
          <p class="text text-left">
            Each user adjusts the clauses and penalties to hers or his likes.
          </p>
          <p>
            <span
              class="color-purple text-underline font-weight-bold font-size-12"
              >REVIEW THE NDA</span
            >
          </p>
          <p class="text text-left">
            If you want to contest or waive the NDA ask Producer James by
            clicking <a href="" class="text-black text-underline"><b>here</b></a
            >.
          </p>
          <p class="text text-left">
            If you don’t accept to agree with the NDA the invitation will cancel
            automatically.
          </p>
          <b-button block class="btn-dark-type-1-custom mt-3 mb-2"
            >Reject</b-button
          >
          <a v-bind:href="'#/profileana/list-alert'">
            <b-button block class="btn-purple-type-1-custom">Accept</b-button>
          </a>
        </b-modal>
      </div>
    </div>
    <NavSakuraFooter></NavSakuraFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
import NavSakura from "../components/includes/NavSakura.vue";
import NavSakuraFooter from "../components/includes/NavSakuraFooter.vue";
export default {
  data() {
    return {
      text: `
          Pending entrees in project. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
        `,
    };
  },
  components: {
    NavSakura,
    NavSakuraFooter,
  },
};
</script>

