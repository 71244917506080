<template>
  <div class="pad-nav-bottom">
    <NavAna></NavAna>
    <div class="pt-5 bg-purple">
      <div class="bg-white border-top-left-right padding-general mt-m-1">
        <b-container class="mt-5 mb-1">
          <b-row class="mb-3">
            <b-col>
              <a
                v-bind:href="'#/profileana/register'"
                class="float-left link-back"
              >
                <img src="../assets/img/icon-arrow-left-black.svg" />
              </a>
              <h2 class="title-header">
                Register<br />
                <span class="text-light">ix activation</span>
              </h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="wrapper fadeInDown pb-4">
                <p class="font-black-12 mt-2 text-left">
                  <b>You have now activated your ix.</b> The indicator of your
                  current position within ein, how high, low, left, right,
                  center and generally speaking how you relate to the network.
                </p>
                <p class="font-black-12 mt-2 text-left">
                  ix will be accesible to you anytime when you click on this
                  icon.
                </p>
                <p class="font-black-12 mt-2 text-left">
                  Cultivating your ix will happen as you take advantage of this
                  tool.
                </p>
                <p class="font-black-12 mt-2 text-left">
                  As you are new to the network your ix depends on your past
                  work and contacts, don’t worry if its too low, there’s only up
                  from here.
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-5 mb-4">
            <b-col>
              <a @click="$bvModal.show('close-project')">
                <b-button block class="btn-purple-type-1-custom pulse"
                  >Learn more</b-button
                >
              </a>
            </b-col>
          </b-row>
        </b-container>
        <b-modal
          ref="close-project"
          id="close-project"
          centered
          hide-footer
          title="ix"
        >
          <b-icon
            @click="$bvModal.hide('close-project')"
            icon="x-circle-fill"
            class="close-modal-level"
          ></b-icon>
          <slither-slider
            id="level"
            :options="{
              adaptiveHeight: true,
              autoplay: false,
              controls: false,
            }"
          >
            <!-- Slide 1 -->
            <div>
              <div class="d-block text-center modal-ix">
                <img src="../assets/img/ix-level-0.svg" class="mb-4" />
                <h3>Level 0</h3>
                <p class="text text-center mb-5">
                  ix level zero means this ein user has: no skills, no ideas, no
                  projects, no products, no opportunities, no experience, no
                  earnings, no contacts, no engagement or very very few, less
                  than 1% of the global possible
                </p>
              </div>
              <div class="mt-3 mb-3">
                <a v-bind:href="'#/profileana/register-ein-setup'">
                  <b-button class="mt-2 btn-purple-type-1-custom pulse" block
                    >Continue</b-button
                  >
                </a>
              </div>
            </div>
            <!-- Slide 2-->
            <div>
              <div class="d-block text-center modal-ix">
                <img src="../assets/img/ix-level-2.svg" class="mb-4" />
                <h3>Level 2</h3>
                <p class="text text-center mb-5">
                  ix level 1 means: the sum of this ein users’ skills, ideas,
                  productions, sales, opportunities, experience, contacts and
                  engagement adds up to a percentage number on the global scale
                  between 20 and 30%. Most users start around here.
                </p>
              </div>
              <a v-bind:href="'#/profileana/register-ein-setup'">
                <b-button class="mt-2 btn-purple-type-2-custom" block
                  >Continue</b-button
                >
              </a>
            </div>
            <!-- Slide 3-->
            <div>
              <div class="d-block text-center modal-ix">
                <img src="../assets/img/ix-level-4.svg" class="mb-4" />
                <h3>Level 4</h3>
                <p class="text text-center mb-5">
                  Your ix level is a reliable way for other users to know more
                  accurately where you are situated relative to them on the ein
                  platform, which helps reduce information asymmetries. Users
                  with the same ix level connect very well and more often.
                </p>
              </div>
              <a v-bind:href="'#/profileana/register-ein-setup'">
                <b-button class="mt-2 btn-purple-type-2-custom" block
                  >Continue</b-button
                >
              </a>
            </div>
            <!-- Slide 4-->
            <div>
              <div class="d-block text-center modal-ix">
                <img src="../assets/img/ix-level-6.svg" class="mb-4" />
                <h3>Level 6</h3>
                <p class="text text-center mb-5">
                  Your ix level helps you know where you are and where you can
                  go, it opens doors for you and it measures how you are
                  perceived beyond likes, views, plays and fol lowers, which are
                  unreliable numbers because those are bought and sold.
                </p>
              </div>
              <a v-bind:href="'#/profileana/register-ein-setup'">
                <b-button class="mt-2 btn-purple-type-2-custom" block
                  >Continue</b-button
                >
              </a>
            </div>
            <!-- Slide 5-->
            <div>
              <div class="d-block text-center modal-ix">
                <img src="../assets/img/ix-level-8.svg" class="mb-4" />
                <h3>Level 8</h3>
                <p class="text text-center mb-5">
                  A high iX level helps you connect faster, with longer reach,
                  it creates more opportunities and gives you an important
                  indication of how you can grow into other areas of culture
                  with our loosing your domain.
                </p>
              </div>
              <a v-bind:href="'#/profileana/register-ein-setup'">
                <b-button class="mt-2 btn-purple-type-2-custom" block
                  >Continue</b-button
                >
              </a>
            </div>
          </slither-slider>
        </b-modal>
      </div>
    </div>
    <NavAnaFooter></NavAnaFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
#level .slither-slider-dot {
  margin-right: 6px !important;
}
</style>
<style>
@import "../../node_modules/slither-slider/plugin-dist/slither-slider.css";
</style>
<script>
import NavAna from "../components/includes/NavAna.vue";
import NavAnaFooter from "../components/includes/NavAnaFooter.vue";
export default {
  data() {
    return {
      value: 30,
      options: [
        {
          text: "Grow your contacts with contacts of your contacts.",
          value: "1",
        },
        {
          text: "Allow ein to manage your space-time availability with access to your calendar.",
          value: "2",
        },
        {
          text: "Allow access to your location and enhance the coordination experience.",
          value: "3",
        },
      ],
    };
  },

  methods: {
    chooseFiles: function () {
      document.getElementById("fileUpload").click();
    },
    hideModal() {
      this.$refs["close-project"].hide();
    },
  },
  components: {
    NavAna,
    NavAnaFooter,
  },
};
</script>

