<template>
  <div class="pt-5 bg-purple">
    <div class="bg-white border-top-left-right padding-general">
      <b-container class="mt-5 mb-1">
        <b-row class="mb-5">
          <b-col>
            <a v-bind:href="'#/profilessakuramanager/create'" class="float-left link-back">
              <img src="../assets/img/icon-arrow-left-black.svg" />
            </a>
            <h2 class="title-header">Enter your<br />mobile number</h2>
            <p class="text-header">We will send a confirmation code</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="wrapper fadeInDown">
              <div id="formContent">
                <!-- Login Form -->
                <form id="form-register" class="form-style">
                  <b-row>
                    <b-col>
                      <p class="phone-number-text"> 
                        <span>+1</span> {{ number }}
                      </p> 
                      <br />
                      <numericpad
                        :onInput="onInput"
                        :onDelete="onDelete"
                        :onReset="onReset"
                        :show="showKeypad"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mt-4 mb-4">
                    <b-col>
                     <a v-bind:href="'#/profilessakuramanager/code'" >
                      <b-button block class="btn-dark-type-2-custom pulse"
                        >Continue</b-button
                      >
                      </a>
                    </b-col>
                  </b-row>
                </form>
                <!-- Create Account -->
                <b-row class="text-center mt-3">
                  <b-col id="join-us">
                    <ul class="list-inline text-center">
                      <li class="list-inline-item">
                        <div class="circle-step"></div>
                      </li>
                      <li class="list-inline-item">
                        <div class="circle-step active"></div>
                      </li>
                      <li class="list-inline-item">
                        <div class="circle-step"></div>
                      </li>
                    </ul>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import numericpad from "@/components/numeric-keypad.vue";

export default {
  name: "app-numeric-keypad",
  components: {
    numericpad
  },
  data: () => ({
    number: "",
    maxLength: 7,
    showKeypad: true
  }),
  methods: {
    onInput(key) {
      this.number = (this.number + key).slice(0, this.maxLength);
    },
    onDelete() {
      this.number = this.number.slice(0, this.number.length - 1);
    },
    onReset() {
      this.number = "";
    }
  }
};
</script>



