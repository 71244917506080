<template>
  <div class="pad-nav-bottom">
    <NavInvestor></NavInvestor>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="10" align-self="center">
            <a
              v-bind:href="'#/profileinvestorsteiner/list-contacts'"
              class="float-left link-back"
            >
              <img
                src="../assets/img/icon-arrow-left-white.svg"
                class="mt-2 mr-2"
              />
            </a>
            <h3 class="title-heder-user text-left">
              <img
                src="../assets/img/img-profile-sakura-manager-copy.png"
                width="40"
              />
              Investor Steiner
            </h3>
          </b-col>
          <b-col cols="2" align-self="center">
            <img
              id="show-btn"
              src="../assets/img/icon-header-more.svg"
              class="icon-menu-footer float-right mt-2"
            />
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general pb-4">
        <b-container>
          <b-row class="mt-3 mb-2">
            <b-col id="content-chat" cols="12 ">
              <div class="bg-purple-light p-3 mt-4 text-left mb-4">
                <p class="color-purple font-size-14 mt-2">
                  <b>Same chat we saw with Sakura Manager,</b> if you would like
                  to review it please access through her profile.
                </p>
              </div>
            </b-col>
          </b-row>
          

        </b-container>
      </div>
    </div>
    <NavInvestorFooter></NavInvestorFooter>
  </div>
</template>

<script>
import NavInvestor from "../components/includes/NavInvestor.vue";
import NavInvestorFooter from "../components/includes/NavInvestorFooterChat.vue";
export default {
  methods: {},
  data() {
    return {
      isActive: false,
      selected: [], // Must be an array reference!
      options: [
        { text: "You have worked together on ein in the past.", value: "1" },
        {
          text: "I want ein to recommend a possible collaborator.",
          value: "2",
        },
        {
          text: "I know someone that would like to be on ein that I can contact directly.",
          value: "3",
        },
      ],
      value1: 75,
      value2: 100,
      value3: 20,
      number: "150",
      maxLength: 4,
      showKeypad: true,
    };
  },
  components: {
    NavInvestor,
    NavInvestorFooter,
  },
};
</script>

