<template>
  <div class="pad-nav-bottom">
    <Nav></Nav>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="10" align-self="center">
            <h3 class="subtitle-detail-alert text-left">
              Pre Production:<br />
              <b>Looking at the Sky</b><br />
              <span class="mt-2 d-inline-block"
                >Chat / <b>Collaboration agreement</b></span
              >
            </h3>
          </b-col>
          <b-col cols="2" align-self="center">
            <img
              id="show-btn"
              src="../assets/img/icon-ein-menu-more.svg"
              class="icon-menu-footer float-right"
            />
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row class="mt-2 mb-1">
            <b-col cols="12">
              <div class="header-detail-project text-left border-bottom mb-1">
                <p class="font-black-12-bold mt-3">
                  Work proposal
                  <span class="text-price-project float-right"
                    >Guitarist
                    <span class="color-purple font-weight-bold"
                      >${{ number }}</span
                    >
                    / day
                  </span>
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-3 mb-2">
            <b-col id="content-chat" cols="12 ">
              <div class="recivid-chat mb-1">
                <div class="img-chat">
                  <div style="height: 32px; widht: 32px"></div>
                </div>
                <div class="text-chat">
                  <div class="bg-chat">
                    <p>
                      Hi James, I've read everything and I like your project. I
                      would like to negotiante some terms if you are up for it.
                    </p>
                  </div>
                </div>
              </div>
              <div class="recivid-chat mb-3">
                <div class="img-chat">
                  <img src="../assets/img/img-profile-ana-artist.png" />
                </div>
                <div class="text-chat">
                  <div class="bg-chat">
                    <p>
                      Because we are going out of town I would like $200 per
                      day.
                    </p>
                  </div>
                  <p class="date-chat">11/08/2020, 19:21</p>
                </div>
              </div>
              <!--<div class="send-chat">
                <div class="img-chat"></div>
                <div class="text-chat">
                  <div class="bg-chat mb-1">
                    <p>
                      Ana! I recommend you update your experience in the DX
                      section and your rates so all this can adjust
                      automatically.
                    </p>
                  </div>
                  <div class="bg-chat">
                    <p>
                      Ana! I recommend you update your experience in the DX
                      section and your rates so all this can adjust
                      automatically.
                    </p>
                  </div>
                  <p class="date-chat">
                    11/08/2020, 19:21
                    <img src="../assets/img/icon-tick-square-white.svg" />
                  </p>
                </div>
              </div>-->
            </b-col>
          </b-row>
        </b-container>
        <numericpad
          v-bind:class="{ active: isActive }"
          :onInput="onInput"
          :onDelete="onDelete"
          :onReset="onReset"
          :show="showKeypad"
        />
        <b-modal id="actions-detail-alert" hide-footer>
          <template #modal-title> </template>
          <div class="d-block text-left padding-general">
            <a v-bind:href="'#/home/alerts/detail'">
              <p class="font-black-18 mb-5">
                <img
                  src="../assets/img/icon-ein-view-password-grey.svg"
                  class="icon-modal mr-2"
                />
                Review project
              </p>
            </a>

            <p @click="isActive = false" class="font-black-18 mb-5">
              <img
                @click="isActive = false"
                src="../assets/img/icon-edit.svg"
                class="icon-modal mr-2"
              />
              Edit project
            </p>
            <a v-b-modal.close-project>
              <p class="font-black-18 mb-5">
                <img
                  src="../assets/img/icon-close-project.svg"
                  class="icon-modal mr-2"
                />
                Close project
              </p>
            </a>
            <div class="text-center">
              <img
                src="../assets/img/icon-arrow-down-purple.svg"
                class="mx-auto"
              />
            </div>
          </div>
        </b-modal>
        <b-modal
          ref="close-project"
          id="close-project"
          centered
          hide-footer
          title="Terms"
        >
          <div class="d-block text-left">
            <p>
              Once you accept the terms of agreement you are subject to legal
              review by law and the ein platform statutes if the terms are
              violated. You will receive a copy of the chat and agreement in
              your inbox and email.
              <span
                class="
                  color-purple
                  text-underline text-uppercase
                  font-weight-bold
                "
                >Modify email</span
              >
            </p>
            <p class="text">
              To insure and secure, all payments are to be made through the ein
              platform and into the card you have declared to be your main
              account.
              <span
                class="
                  color-purple
                  text-underline text-uppercase
                  font-weight-bold
                "
                >Modify card</span
              >
            </p>
            <p class="text">
              Remember ein doesn’t retain any tax on your behalf and any
              payments required by law in your country are responsibility of the
              user.
              <span
                class="
                  color-purple
                  text-underline text-uppercase
                  font-weight-bold
                "
                >I have a different legal entity.</span
              >
            </p>
            <p class="text">
              As soon as <b>Producer James</b> processes the upfront payment of
              30%, as declared in the terms ein will take 0.5% as a commission
              cost, tax included.
            </p>
            <p class="text-small text-left mb-5">
              <img
                src="../assets/img/icon-close-project-hover.svg"
                class="icon-modal mr-2"
              />Lead guitarist is missing.
            </p>
          </div>
          <a v-bind:href="'#/negotiation/Confirmation'">
            <b-button class="mt-2 btn-purple-type-2-custom" block
              >Sign</b-button
            >
          </a>
          <b-button class="mt-3 btn-dark-type-1-custom" block @click="hideModal"
            >Cancelar</b-button
          >
        </b-modal>
      </div>
    </div>
    <NavFooter></NavFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
.keypad-dialog {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.keypad-dialog {
  display: none;
}
.keypad-dialog.active {
  display: initial;
  z-index: 2000;
}
#send-text-message .form-control {
  text-shadow: 2px 1px 9px rgba(94, 38, 218, 1) !important;
}
</style>
<script>
import numericpad from "@/components/numeric-keypad.vue";
import Nav from "../components/includes/Nav.vue";
import NavFooter from "../components/includes/NavFooterChat.vue";
export default {
  methods: {
    hideModal() {
      this.$refs["close-project"].hide();
    },
    onInput(key) {
      this.number = (this.number + key).slice(0, this.maxLength);
    },
    onDelete() {
      this.number = this.number.slice(0, this.number.length - 1);
    },
    onReset() {
      this.number = "";
    },
  },
  data() {
    return {
      isActive: false,
      selected: [], // Must be an array reference!
      options: [
        { text: "You have worked together on ein in the past.", value: "1" },
        {
          text: "I want ein to recommend a possible collaborator.",
          value: "2",
        },
        {
          text: "I know someone that would like to be on ein that I can contact directly.",
          value: "3",
        },
      ],
      value1: 75,
      value2: 100,
      value3: 20,
      number: "150",
      maxLength: 4,
      showKeypad: true,
    };
  },
  components: {
    Nav,
    numericpad,
    NavFooter
  },
};
</script>

