<template>
  <div>
    <div v-if="loading1">
      <div class="bg-white vh-100">
        <!-- Loading bar markup here -->
        <b-container class="vh-100">
          <b-row>
            <b-col align-self="center" class="vh-100">
              <img
                id="logo_launch"
                alt="Vue logo"
                src="../assets/img/logo-ein-launch-screen.svg"
              />
              <h2 class="title-launch">entertainment<br />industry network</h2>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <div v-if="loading2">
      <div class="bg-purple vh-100">
        <!-- Loading bar markup here -->
        <b-container class="vh-100">
          <b-row>
            <b-col align-self="center" class="vh-100 p-screen-2">
              <h2 class="text-white title-launch-screen-2">
                This demo was designed to show how the ein platform creates fast
                adoption,
                <span>how it organically grows and how it monetizes</span>.
              </h2>
              <p class="text-white subtitle-launcher">
                We are presenting four fictional characters to showcase this
                coordination tool.
              </p>
              <div id="custom-launch" class="text-left mt-5">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="status"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                  class="text-white"
                >
                  I accept the terms and use
                </b-form-checkbox>
              </div>
              <b-button  v-on:click="mostrar" block class="btn-dark-type-1-custom mb-2 box-s-white border-0 mt-5"
                >Continue</b-button
              >
              <p class="text-white mt-5 corpy">
                © EIN LLC 2021
              </p>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <div v-if="loading3" class="padding-general pt-2">
      <b-container>
        <b-row class="mb-4">
          <b-col class="text-left">
            <img
              alt="Vue logo"
              src="../assets/img/logo-ein-onboarding-screen.svg"
            />
          </b-col>
          <b-col class="text-right" align-self="center">
            <b-icon icon="x" scale="2" variant="dark"></b-icon>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <slither-slider
              :options="{
                adaptiveHeight: true,
                autoplay: true,
                controls: false,
              }"
            >
              <!-- Slide 1 -->
              <div>
                <img
                  alt="Vue logo"
                  src="../assets/img/img-onboarding-screen-A.svg"
                />
                <h2 class="text-left">
                  Manage<br />
                  your next<br />
                  production
                </h2>
              </div>

              <!-- Slide 2 -->
              <div>
                <img
                  alt="Vue logo"
                  src="../assets/img/img-onboarding-screen-B.svg"
                />
                <h2 class="text-left">
                  Projects,<br />
                  Opportunities,<br />
                  Investors...
                </h2>
              </div>

              <!-- Slide 3 -->
              <div>
                <img
                  alt="Vue logo"
                  src="../assets/img/img-onboarding-screen-C.svg"
                />
                <h2 class="text-left">
                  Projects,<br />
                  Opportunities,<br />
                  Investors...
                </h2>
              </div>

              <!-- Slide 4 -->
              <div>
                <img
                  alt="Vue logo"
                  src="../assets/img/img-onboarding-screen-D.svg"
                />
                <h2 class="text-left">
                  Set up your<br />
                  new production<br />
                  in a few clicks
                </h2>
              </div>
            </slither-slider>
          </b-col>
        </b-row>

        <div id="btn-absolute">
          <b-row class="mt-5 mb-4">
            <b-col>
              <!--<router-link :to="{ name: 'Create' }">-->
              <b-button block class="btn-dark-type-1-custom mb-2"
                >Join Us</b-button
              >
              <!--</router-link>-->
              <router-link :to="{ name: 'Signin' }">
                <b-button block class="btn-dark-type-2-custom pulse"
                  >Sign In</b-button
                >
              </router-link>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading1: true,
      loading2: false,
      loading3: false,
    };
  },
  components: {},
  methods: {
    sayHi: function () {
      var v = this;
      setTimeout(function () {
        v.loading1 = false;
      }, 5000);
      setTimeout(function () {
        v.loading2 = true;
      }, 2000);
    },
    mostrar: function () {
      var v = this;
      // `this` dentro de los métodos apunta a la instancia de Vue
      v.loading2 = false;
      v.loading3 = true;
    }
  },
  mounted() {
    this.sayHi();
  },
};
</script>
<style>
@import "../../node_modules/slither-slider/plugin-dist/slither-slider.css";
.show-enter-active,
.show-leave-enter {
  transform: translateX(0);
  transition: all 0.3s linear;
}
.show-enter,
.show-leave-to {
  transform: translateX(100%);
}
</style>
