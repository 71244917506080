<template>
  <div class="pad-nav-bottom">
    <NavInvestor></NavInvestor>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3 pl-10 pr-10">
          <b-col cols="12" align-self="center">
            <h3 class="subtitle-h3-white text-left">
              <b>5</b> Notifications
            <img
                src="../assets/img/icon-menu-alert.svg"
                class="icon-menu-footer float-right ml-3"
              />
                            <img
                src="../assets/img/icon-filter-alert.svg"
                class="icon-menu-footer float-right "
              />
            </h3>
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row id="according-content" class="mt-2 mb-2">
            <b-col cols="12">
              <div class="accordion" role="tablist">
                 <!--new-->
                <b-card no-body class="mb-2">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div class="divider-left-purple divider-absolute"></div>
                    <div class="header-according-link">
                      <a v-bind:href="'#/profileinvestorsteiner/register'">
                        <span class="title-according text-s-purple">
                          Investment - Looking at…
                        </span>
                      </a>
                      <span class="date-text float-right">
                        31/07/2020
                        <img
                          src="../assets/img/icon-cross-alerts.svg"
                          class="icon-close ml-2 delete-item"
                        />
                      </span>
                    </div>
                    <h3 class="title-according text-left">
                      <span class="abstract-text-toogle"
                        >Renegotiate contract</span
                      >
                      <!-- replace these with your preferred icons -->
                      <img
                        v-b-toggle.accordion-1
                        src="../assets/img/icon-arrow-down-black.svg"
                        class="icon-close ml-2 mt-2 float-right"
                      />
                    </h3>
                  </b-card-header>
                  <b-collapse
                    id="accordion-1"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text> <p>
                          ein found you a good investment opportunity<br />
                          <span class="mt-2 text-dark d-block">
                            <b>Managed by: </b>
                            <a href="" class="text-dark text-underline pointer-events-none"
                              >Producer James</a
                            >
                          </span>
                        </p>
                        <b-button
                          @click="$bvModal.show('close-project')"
                          block
                          class="btn-small-purple pulse"
                        >
                          2 mutual contacts
                        </b-button></b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <hr class="mt-1 mb-2" />
                <b-card no-body class="mb-2">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div class="divider-left-purple divider-absolute"></div>
                    <div class="header-according-link">
                     <a
                        v-bind:href="'#/profileinvestorsteiner/register'"
                        class="pointer-events-none"
                      >
                        <span class="title-according"
                          >Transaction - GlobalMusic</span
                        >
                      </a>
                      <span class="date-text float-right">
                        18/01/2021
                        <img
                          src="../assets/img/icon-cross-alerts.svg"
                          class="icon-close ml-2 delete-item"
                        />
                      </span>
                    </div>
                    <h3 class="title-according text-left">
                      <span class="abstract-text-toogle"
                        >Review budget</span
                      >
                      <!-- replace these with your preferred icons -->
                      <img
                        v-b-toggle.accordion-2
                        src="../assets/img/icon-arrow-down-black.svg"
                        class="icon-close ml-2 mt-2 float-right"
                      />
                    </h3>
                  </b-card-header>
                  <b-collapse
                    id="accordion-2"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text>{{ text }}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <hr class="mt-1 mb-2" />
                <b-card no-body class="mb-2">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div class="divider-left-purple divider-absolute"></div>
                    <div class="header-according-link">
                      <a
                        v-bind:href="'#/home/alerts/detail'"
                        class="pointer-events-none title-according"
                      >
                        Following - Sam Eoxas 
                      </a>
                      <span class="date-text float-right">
                        05/01/2021
                        <img
                          src="../assets/img/icon-cross-alerts.svg"
                          class="icon-close ml-2 delete-item"
                        />
                      </span>
                    </div>
                    <h3 class="title-according text-left">
                      <span class="abstract-text-toogle"
                        >Uploaded an idea to the…</span
                      >
                      <!-- replace these with your preferred icons -->
                      <img
                        v-b-toggle.accordion-3
                        src="../assets/img/icon-arrow-down-black.svg"
                        class="icon-close ml-2 mt-2 float-right"
                      />
                    </h3>
                  </b-card-header>
                  <b-collapse
                    id="accordion-3"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text>{{ text }}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <hr class="mt-1 mb-2" />
                <b-card no-body class="mb-2">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div class="divider-left-purple divider-absolute"></div>
                    <div class="header-according-link">
                      <a
                        v-bind:href="'#/home/alerts/detail'"
                        class="pointer-events-none title-according"
                      >
                        Agreement - R. Tramonti 
                      </a>
                      <span class="date-text float-right">
                        05/01/2021
                        <img
                          src="../assets/img/icon-cross-alerts.svg"
                          class="icon-close ml-2 delete-item"
                        />
                      </span>
                    </div>
                    <h3 class="title-according text-left">
                      <span class="abstract-text-toogle">Investment will expire in a week …</span>
                      <!-- replace these with your preferred icons -->
                      <img
                        v-b-toggle.accordion-4
                        src="../assets/img/icon-arrow-down-black.svg"
                        class="icon-close ml-2 mt-2 float-right"
                      />
                    </h3>
                  </b-card-header>
                  <b-collapse
                    id="accordion-4"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text>{{ text }}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <hr class="mt-1 mb-2" />
                <b-card no-body class="mb-2">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div class="divider-left-yellow divider-absolute"></div>
                    <div class="header-according-link">
                      <a
                        v-bind:href="'#/home/alerts/detail'"
                        class="pointer-events-none title-according"
                      >
                        Analyze - Aluara
                      </a>
                      <span class="date-text float-right">
                        02/01/2021
                        <img
                          src="../assets/img/icon-cross-alerts.svg"
                          class="icon-close ml-2 delete-item"
                        />
                      </span>
                    </div>
                    <h3 class="title-according text-left">
                      <span class="abstract-text-toogle"
                        >Investment trajectory.</span
                      >
                      <!-- replace these with your preferred icons -->
                      <img
                        v-b-toggle.accordion-5
                        src="../assets/img/icon-arrow-down-black.svg"
                        class="icon-close ml-2 mt-2 float-right"
                      />
                    </h3>
                  </b-card-header>
                  <b-collapse
                    id="accordion-5"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text>{{ text }}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <hr class="mt-1 mb-2" />
                <!--new-->
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-modal
          ref="recording-modal"
          id="close-project"
          centered
          hide-footer
          title="Mutual Contacts"
        >
          <b-icon
            @click="$bvModal.hide('close-project')"
            icon="x-circle-fill"
            class="close-modal-level"
          ></b-icon>
          <b-row id="contacts-content" class="mt-4 mb-5">
            <b-col cols="12">
              <b-list-group>
                <b-list-group-item
                  class="d-flex align-items-center contacts-modal"
                >
                  <b-avatar class="mr-3">
                    <img
                      src="../assets/img/img-profile-sakura-manager.png"
                      class="icon_user"
                    />
                  </b-avatar>
                  <span class="text-left">
                    Sakura Manager<br />
                    <span class="text-light font-size-12"
                      >Works with Producer James.</span
                    >
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  class="d-flex align-items-center contacts-modal"
                >
                  <b-avatar class="mr-3">
                    <img
                      src="../assets/img/img-profile-raimunda-clorft.png"
                      class="icon_user"
                    />
                  </b-avatar>
                  <span class="text-left">
                    Raimunda Clorft<br />
                    <span class="text-light font-size-12"
                      >Has worked with Producer James.</span
                    >
                  </span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <a v-bind:href="'#/profileinvestorsteiner/list-contacts'">
            <b-button block class="btn-dark-type-2-custom pulse"
              >View contacts</b-button
            >
          </a>
        </b-modal>
      </div>
    </div>
    <NavInvestorFooter></NavInvestorFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
import NavInvestor from "../components/includes/NavInvestor.vue";
import NavInvestorFooter from "../components/includes/NavInvestorFooter.vue";
export default {
  data() {
    return {
      text: '',
    };
  },
  methods: {
    hideModal() {
      this.$refs["close-project"].hide();
    },
  },
  components: {
    NavInvestor,
    NavInvestorFooter,
  },
};
</script>

