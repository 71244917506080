<template>
  <div class="pad-nav-bottom">
    <NavInvestor></NavInvestor>
    <div class="pt-2 bg-purple">
      <b-container>
        <b-row class="mt-2 mb-3">
          <b-col cols="12" align-self="center">
            <a
              v-bind:href="'#/profileinvestorsteiner/register'"
              class="float-left link-back"
            >
              <img src="../assets/img/icon-arrow-left-white.svg" class="mr-3" />
            </a>
            <h3 class="title-heder-user text-left mt-1">
              <b>Right Space:</b> Looking at the Sky
            </h3>
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-top-left-right padding-general">
        <b-container>
          <b-row class="mt-5 mb-4">
            <b-col>
              <div class="wrapper fadeInDown">
                <b-row class="mt-2 mb-2">
                  <b-col class="text-center name-profile">
                    <img
                      src="../assets/img/img-profile-producer-james.png"
                      class="img-user-80"
                    />
                    <h3>Producer James</h3>
                    <p>ein user</p>
                  </b-col>
                </b-row>
                <b-row class="mt-2 mb-2">
                  <b-col id="sections" class="text-left w-100 scroll-y">
                    <div class="scroll-w">
                      <button class="btn-section-prfofile active mb-2">
                        Personal info
                      </button>
                      <button
                        @click="scrollMeTo('skills')"
                        class="btn-section-prfofile mb-2"
                      >
                        Disciplines and skills
                      </button>
                      <button
                        @click="scrollMeTo('ein')"
                        class="btn-section-prfofile mb-2"
                      >
                        EIN
                      </button>
                      <button
                        @click="scrollMeTo('skills')"
                        class="btn-section-prfofile mb-2"
                      >
                        Disciplines and skills
                      </button>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4 mb-4">
                  <b-col>
                    <ul class="list-unstyled text-left list-info-user">
                      <li>
                        <img
                          src="../assets/img/icon-ein-profile-user.svg"
                          class="icon-info-user"
                        />
                        38 years old
                      </li>
                      <li>
                        <img
                          src="../assets/img/icon-ein-profile-location.svg"
                          class="icon-info-user"
                        />
                        5139 26th Ave NE Seattle, WA 98105, EEUU
                      </li>
                      <li>
                        <img
                          src="../assets/img/icon-ein-profile-mail.svg"
                          class="icon-info-user"
                        />
                        <a
                          v-bind:href="':mailto:producer.james@roundhouse.com'"
                          class="link-purple-small"
                          >producer.james@roundhouse.com</a
                        >
                      </li>
                      <li>
                        <img
                          src="../assets/img/icon-ein-profile-company.svg"
                          class="icon-info-user"
                        />
                        CEO Round House Entertainment
                      </li>
                      <li>
                        <img
                          src="../assets/img/icon-ein-profile-hourly-wage.svg"
                          class="icon-info-user"
                        />
                        Hourly wage $350
                      </li>
                    </ul>
                  </b-col>
                </b-row>
                <b-row class="mt-4 mb-4 border-top pt-4">
                  <b-col class="text-left">
                    <div class="item-likes item-likes-green mr-2 mb-2">
                      <p>Music</p>
                    </div>
                    <div
                      class="item-likes item-likes-green like-badge mr-2 mb-2"
                    >
                      <p>
                        Producer<b-badge class="badge-green">10 yrs</b-badge>
                      </p>
                    </div>
                    <div class="item-likes item-likes-green mr-2 mb-2">
                      <p>Composer</p>
                    </div>
                    <div class="item-likes item-likes-green mr-2 mb-2">
                      <p>Instruments</p>
                    </div>
                    <div
                      class="item-likes item-likes-green like-badge mr-2 mb-2"
                    >
                      <p>Piano<b-badge class="badge-green">10 yrs</b-badge></p>
                    </div>
                    <div class="item-likes item-likes-yellow mr-2 mb-2">
                      <p>Video</p>
                    </div>
                    <div class="item-likes item-likes-yellow mr-2 mb-2">
                      <p>Events Coordinator</p>
                    </div>
                    <div class="item-likes item-likes-orange mr-2 mb-2">
                      <p>Tour Manager</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4 mb-4 border-top pt-4">
                  <b-col class="text-left">
                    <ul class="list-unstyled text-left list-info-user">
                      <li>
                        <img
                          src="../assets/img/icon-check.svg"
                          class="icon-info-user"
                        />
                        He’s an original ein user
                      </li>
                      <li>
                        <img
                          src="../assets/img/icon-check.svg"
                          class="icon-info-user"
                        />
                        His DX is high
                      </li>
                      <li>
                        <img
                          src="../assets/img/icon-check.svg"
                          class="icon-info-user"
                        />
                        He’s producing a project with ein
                      </li>
                      <li>
                        <img
                          src="../assets/img/icon-check.svg"
                          class="icon-info-user"
                        />
                        He invites Ana
                      </li>
                      <li>
                        <img
                          src="../assets/img/icon-check.svg"
                          class="icon-info-user"
                        />
                        He has a relationship with Sakura
                      </li>
                    </ul>
                  </b-col>
                </b-row>
                <b-row class="mt-4 mb-2">
                  <b-col>
                    <b-button block class="btn-purple-type-2-custom"
                      >Enter</b-button
                    >
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <NavInvestorFooter></NavInvestorFooter>
  </div>
</template>
<style scoped>
input {
  margin-right: -30px;
  padding-right: 35px;
}
.strong-label {
  margin-right: 10px;
  font-weight: 900;
}
.display-eye {
  cursor: pointer;
}
</style>
<script>
import NavInvestor from "../components/includes/NavInvestor.vue";
import NavInvestorFooter from "../components/includes/NavInvestorFooter.vue";
export default {
  data() {
    return {
      password: "",
      password_type: "password",
      password_toggle_element: "show",
    };
  },

  methods: {
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
  },
  components: {
    NavInvestor,
    NavInvestorFooter,
  },
};
</script>

